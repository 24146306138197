import React from 'react';
import { View, ScrollView, Pressable, Image, useWindowDimensions } from 'react-native';
import { create, useDeviceContext } from 'twrnc';
import { Heading, PrimaryNav, Span, Button, Modal, Notification, HorizontalRule, ImagesUploadInput, AddNewAssetButtons } from '../elements';
import { useSpring, animated } from 'react-spring';
import { goPrevScreen, getLabel, now, date_DMJYYYY, getUnreadNotificationsCount, sort_by, processImagesInput, uploadImages, getAssetPictures } from '../helpers';
import { GlobalContext } from '../global';
import { useToast } from 'react-native-toast-notifications';

function YachtPhotosView ({route, navigation})
{
    const AnimatedView = animated(View);
    const tw = create( require(`../../tailwind.config.js`) );
    useDeviceContext(tw);
    const {height, width} = useWindowDimensions();
    const toast = useToast();

    let {
        apiUrl,
        apiVersion,
        settings,
        setLastUpdate,
        loggedIn, setLoggedIn,
        userYachts,
        userNotifications,
        themes, theme
    } = React.useContext(GlobalContext);

    React.useEffect(()=>
    {
        if ( ! loggedIn ) { navigation.navigate('loading'); }
    },[loggedIn, navigation]);

    const labels = React.useRef(null);
    React.useEffect(()=>
    {
        if ( ! labels.current )
        {
            labels.current = {
                _ASSETS: getLabel('PHOTOS'),
                _ASSET: getLabel('PHOTO'),
                _404_TEXT: getLabel('PHOTOS_404_TEXT'),

                VIEW: getLabel('VIEW'),
                DELETE: getLabel('DELETE'),
                CANCEL: getLabel('CANCEL'),
                NOTIFICATIONS: getLabel('NOTIFICATIONS'),
                CONTENT_ADD: getLabel('CONTENT_ADD'),
                ADD: getLabel('ADD'),
                BACK: getLabel('BACK'),

                DELETE_CONFIRM_TITLE: getLabel('DELETE_CONFIRM_TITLE'),
                DELETE_CONFIRM_BODY: getLabel('DELETE_CONFIRM_BODY'),
                PERMANENT_ACTION: getLabel('PERMANENT_ACTION'),

                TOAST_ADD: getLabel('TOAST_ADD'),
                TOAST_DELETE: getLabel('TOAST_DELETE'),
            };
        }
    },[]);

    const [notificationCount, setNotificationCount] = React.useState(0);
    React.useEffect(()=>
    {
        setNotificationCount(getUnreadNotificationsCount())
    },[userNotifications]);

    const { yachtID } = route.params;
    const [ yacht, setYacht ] = React.useState(0);

    // ref for photo type
    const photoObjectType = React.useRef('obj_vessel');

    const [primaryMsg, setPrimaryMsg] = React.useState('');
    const [primaryMsgType/*, setPrimaryMsgType*/] = React.useState('warning');
    const [modalActive, setModalActive] = React.useState(false);
    const [modalAffectID, setModalAffectID] = React.useState('');

    const [addingNew, setAddingNew] = React.useState(false);

    const [viewingAsset, setViewingAsset] = React.useState(false);
    const [viewingAssetObj, setViewingAssetObj] = React.useState({});

    const [newAssetMsg, setNewAssetMsg] = React.useState('');
    const [newAssetMsgType, setNewAssetMsgType] = React.useState('warning');
    const [newAssetValidation, doNewAssetValidation] = React.useState(false);
    const [getAssets, setAssets] = React.useState([]);

    const [isImageUploading, setIsImageLoading] = React.useState(false);

    // input refs for asset specific inputs
    const newPhotosInput = React.useRef(null);

    React.useEffect(() => {
        let assets = [];

        if ( yachtID )
        {
            let yachtObj = false;
            userYachts.every((value, index) => {
                if ( yachtID == value.id ) { setYacht(value); yachtObj = value; return false; }
                return true;
            });

            if ( getAssetPictures(yachtObj.id, yachtObj.id, userYachts).length )
            {
                let photos = getAssetPictures(yachtObj.id, yachtObj.id, userYachts).sort(sort_by('modified_date', true));
                let imageWidth = width/3;
                photos.forEach((asset, index) => {
                    assets.push(
                        <Pressable
                            key={index}
                            style={tw`h-[${imageWidth}px] w-[${imageWidth}px] md:h-32 md:w-32`}
                            onPress={()=>{
                                // recalculate optimal image display width and height given the max height should be 80vh for md devices and height - 142 for sm devices, and a max width for md devices being 80vw and 100vw for sm devices
                                let displayWidth = width;
                                let displayHeight = height - 142;
                                if ( tw.prefixMatch('md') ) { displayWidth = Math.floor(width * 0.8); displayHeight = Math.floor(height * 0.8); }
                                if ( asset.width > displayWidth ) {
                                    asset.height = Math.ceil((displayWidth / asset.width) * asset.height);
                                    asset.width = displayWidth;
                                }
                                if ( asset.height > displayHeight ) {
                                    asset.width = Math.floor((displayHeight / asset.height) * asset.width);
                                    asset.height = displayHeight;
                                }
                                setViewingAssetObj(asset);
                                setViewingAsset(true);
                            }}
                        >
                            <Image
                                source={{ uri: asset.uri }}
                                resizeMode="cover"
                                style={[tw`bg-gray1 w-full h-full border-b border-white`, (index + 1) % 3 === 0 ? tw`border-r-0 md:border-r` : tw`border-r`]}
                            />
                        </Pressable>
                    )
                });
            }
        }

        setAssets(assets);
    },[yachtID, userYachts, setAssets]);


    let viewPaneAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: viewingAsset ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: viewingAsset ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    let viewPaneAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
        },
        to: {
            opacity: viewingAsset ? 1 : 0,
            pointerEvents: viewingAsset ? 'auto' : 'none',
        },
        config: viewingAsset ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    viewPaneAnimation = tw.prefixMatch('md') ? viewPaneAnimationDesktop : viewPaneAnimation;

    return (
        <View label="yachtPhotos" style={tw`bg-gray0 h-screen md:flex-row overflow-hidden max-w-screen`}>

            <PrimaryNav navigation={navigation} setLoggedIn={setLoggedIn} hideOnMobile={true} yachtID={yachtID} />

            <View style={tw`bg-[${themes[theme].brand_primary}] h-20 flex-row md:hidden`}>
                <View style={tw`bg-[${themes[theme].brand_primary}] h-20 p-5 pt-6.5 flex-row`}>
                    <Pressable
                        style={tw`p-2 -ml-2 -mt-2`}
                        onPress={() => { goPrevScreen(navigation, 'yacht', {yachtID: yachtID}) }}
                    >
                        <Image
                            accessibilityLabel={ labels.current?.CANCEL }
                            source={{ uri: require('../svg/back_white.svg') }}
                            resizeMode="contain"
                            style={tw`h-6 w-6 top-px mr-1`}
                        />
                    </Pressable>
                    <Span style={tw`bg-${yacht.color || 'gray6'} text-white uppercase rounded-full text-sm font-semibold shrink-0 w-8 h-8 mr-3 text-center leading-[31px] -mt-[2px]`}>{yacht.initials}</Span>
                    <Heading style={tw`mt-1.5 leading-4 mb-0 text-white`} hlevel={1} styleHlevel={4}>
                        { labels.current?._ASSETS }
                    </Heading>
                </View>

                <View style={tw`grow`}></View>

                <View style={tw`w-18 justify-center md:order-2 md:w-full`}>
                    <Pressable
                        onPress={()=>{navigation.navigate('notifications')}}
                        style={tw`p-5 relative md:flex-row md:pl-15 md:py-3 md:my-2`}
                    >
                        { notificationCount > 0 &&
                            <Span style={tw`bg-white text-[${themes[theme].brand_primary}] rounded-full min-w-4 h-4 text-center text-xs font-bold absolute bottom-1/2 left-1/2 md:left-18 z-10 pointer-events-none`}>
                                { notificationCount }
                            </Span>
                        }

                        <Image
                            accessibilityLabel={ labels.current?.NOTIFICATIONS }
                            source={{ uri: require('../svg/notifications.svg') }}
                            resizeMode="contain"
                            style={tw`h-5 md:h-8 w-full md:w-8 relative z-0 -left-1`}
                        />

                        <Span style={tw`hidden md:flex font-bold text-base text-white leading-8 ml-4 tracking-wide`}>{ labels.current?.NOTIFICATIONS }</Span>

                    </Pressable>
                </View>
            </View>

            <View style={tw`hidden md:flex bg-[${themes[theme].brand_primary}] h-8 md:h-full md:w-10 z-0 -mb-3 md:mb-0 md:-mr-3`}>
                <View style={tw`bg-gray0 h-full w-full rounded-t-3xl md:rounded-l-3xl md:rounded-r-0 z-10 md:shadow-black md:shadow-opacity-10 md:shadow-radius-4`}></View>
            </View>

            <View style={[tw`bg-gray0 grow md:pr-8 md:pl-4 z-20`, {width: tw.prefixMatch('md') ? 'calc(100% - 320px)' : '100%', height: tw.prefixMatch('md') ? '100%' : 'calc(100% - 112px)'}]}>

                <View style={! getAssets.length ? tw`grow` : tw`shrink`}>
                    <ScrollView
                        centerContent={getAssets.length ? false : true}
                        style={{width:'100%',overflowX:'visible'}}
                        contentContainerStyle={tw`w-full min-h-full justify-start`}
                    >
                        <View style={tw`hidden md:flex`}>
                            <View style={tw`flex-row w-full items-center justify-between lg:justify-start mt-2 md:mt-6 lg:mt-10 mb-0 md:mb-1 lg:mb-5 md:px-1`}>
                                <Span style={tw`bg-${yacht.color || 'gray6'} text-white uppercase rounded-full text-lg font-semibold shrink-0 w-14 h-14 mr-3 text-center leading-[56px]`}>{yacht.initials}</Span>

                                <Heading style={tw`leading-4 hidden md:flex`} hlevel={1} styleHlevel={2}>
                                    {yacht.name}
                                </Heading>
                            </View>
                        </View>

                        { '' !== primaryMsg &&
                            <View style={tw`w-full px-4 md:px-1`}>
                                <Notification
                                    style={tw`my-4`}
                                    type={primaryMsgType}
                                >{primaryMsg}</Notification>
                            </View>
                        }
                        { ! getAssets.length &&
                            <View style={tw`justify-center grow items-center`}>

                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('../svg/photos_lightgray.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-32 w-32 md:h-48 md:w-48`}
                                />
                                <Heading style={tw`text-gray5 text-center p-5 mb-26`} hlevel={tw.prefixMatch('md') ? 2 : 3}>{ labels.current?._404_TEXT }</Heading>

                                <View style={tw`fixed bottom-9 md:bottom-18 right-18 pointer-events-none`}>

                                    <Span style={tw`text-sm md:text-base font-medium font-sans mb-2 mr-6`}>{ labels.current?.CONTENT_ADD }</Span>

                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/'+theme+'/arrow_organic.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-9 w-18 left-14 md:left-16`}
                                    />
                                </View>
                            </View>
                        }

                        { getAssets.length > 0 &&
                            <View>
                                <Heading hlevel={2} styleHlevel={4} style={tw`hidden md:flex pl-1`}>{ labels.current?._ASSETS }</Heading>

                                <View style={tw`flex-wrap flex-row justify-start items-start mb-20`}>
                                    {getAssets}
                                </View>
                            </View>
                        }

                    </ScrollView>

                    <ImagesUploadInput
                        passedRef={newPhotosInput}
                        onChange={(e)=>{
                            if ( ! e.target.files[0] ) { return; }

                            let imagesArray = processImagesInput(e.target.files, settings.max_size_image).then((imagesArray)=>{
                                if ( 'string' === typeof imagesArray )
                                {
                                    toast.show(imagesArray, {type: 'danger', duration:10000});
                                    return;
                                }
                                setIsImageLoading(true);
                                uploadImages(
                                    imagesArray,
                                    photoObjectType.current,
                                    yachtID,
                                    toast,
                                    labels.current?.TOAST_ADD.replace('{object}',labels.current?._ASSET),
                                    {apiUrl:apiUrl, apiVersion:apiVersion},
                                    (status)=>{
                                        setIsImageLoading(false);
                                        if ( 'success' === status )
                                        {
                                            setLastUpdate(now());
                                        }
                                    }
                                );
                            })
                        }}
                    />

                    <AddNewAssetButtons isLoading={isImageUploading} addNewLabel={ labels.current?.ADD +' '+ labels.current?._ASSET } onAddNew={()=>{
                        setNewAssetMsg('');

                        /* trigger click on #image-picker */
                        newPhotosInput.current.click();
                    }} />
                </View>
            </View>

            <AnimatedView style={viewPaneAnimation}>

                <Pressable style={tw`hidden md:flex bg-[${themes[theme].brand_primary}]/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setViewingAsset(false)}}
                ></Pressable>

                <View style={tw`w-full md:w-[${viewingAssetObj.width}px] md:max-w-[80vw] md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>

                    <View style={tw`bg-[${themes[theme].brand_primary}] md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:pl-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setViewingAsset(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/'+theme+'/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 leading-4 mb-0 text-white md:hidden`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ labels.current?._ASSET }</Heading>
                        <View style={tw`justify-between hidden md:flex md:flex-row grow mt-0 mb-0 items-start h-full`}>
                            <View style={tw`self-center`}>
                                <Heading style={tw`leading-4 mt-0 mb-0 text-ink`} hlevel={1} styleHlevel={4}>{ viewingAssetObj.description }</Heading>
                            </View>

                            <View style={tw`self-center`}>
                                <View style={tw`flex-row items-center`}>
                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/date.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-4 w-4 mr-2`}
                                    />
                                    <Span style={tw`text-gray6 text-sm leading-6 text-right`}>{ date_DMJYYYY( viewingAssetObj.modified_date ) }</Span>

                                    <Pressable
                                        style={(state) => [tw`p-2 rounded bg-gray2 ml-5`, state.hovered && tw`bg-red`]}
                                        onPress={() => {
                                            setModalAffectID(viewingAssetObj.id);
                                            setModalActive(true);
                                        }}
                                    >
                                        {( state ) => (
                                            <Image
                                                accessibilityLabel={labels.current?.DELETE}
                                                source={ state.hovered ? { uri: require('../svg/delete_white.svg') } : { uri: require('../svg/delete.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-6 w-6`}
                                            />
                                        )}
                                    </Pressable>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={tw`bg-[${themes[theme].brand_primary}] h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>

                    <View style={[tw`bg-gray0 grow md:pr-0 md:pb-0 md:pt-0 md:pl-0 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : 'calc(100vh - 100px)'}]}>
                        <View style={tw`h-full md:h-max`}>
                            <View style={tw`w-full justify-between px-5 mb-4 flex-row md:hidden`}>
                                <View style={tw`w-full`}>
                                    <Span style={tw`font-bold`}>{ viewingAssetObj.description }</Span>
                                    <View style={tw`flex-row items-center w-full justify-between`}>
                                        <View style={tw`flex-row items-center`}>
                                            <Image
                                                accessibilityHidden={true}
                                                source={{ uri: require('../svg/date.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-4 w-4 mr-2`}
                                            />
                                            <Span style={tw`text-gray6 text-sm leading-6`}>{ date_DMJYYYY( viewingAssetObj.modified_date ) }</Span>
                                        </View>

                                        <Pressable
                                            style={tw`p-2 rounded bg-gray2 ml-5`}
                                            onPress={() => {
                                                setModalAffectID(viewingAssetObj.key);
                                                setModalActive(true);
                                            }}
                                        >
                                            <Image
                                                accessibilityLabel={labels.current?.DELETE}
                                                source={{ uri: require('../svg/delete.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-6 w-6`}
                                            />
                                        </Pressable>
                                    </View>
                                </View>
                            </View>
                            <HorizontalRule style={tw`md:hidden`} />
                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-0`}
                            >
                                <View style={tw`md:flex-row md:flex-wrap md:justify-between h-[${viewingAssetObj.height}px] max-h-[${height - 142}px] md:max-h-[80vh]`}>
                                    { 'undefined' !== typeof viewingAssetObj?.uri &&
                                        <Image
                                            source={{ uri: viewingAssetObj.uri }}
                                            resizeMode="contain"
                                            style={tw`w-full max-w-full h-auto min-h-full md:rounded-b-md`}
                                        />
                                    }
                                </View>
                            </ScrollView>
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <Modal
                active={modalActive}
                setActive={setModalActive}
            >
                <Heading hlevel={2} styleHlevel={3}>{ labels.current?.DELETE_CONFIRM_TITLE }</Heading>
                <Span>{ labels.current?.DELETE_CONFIRM_BODY }</Span>
                <Span style={tw`text-red mt-2`}>{ labels.current?.PERMANENT_ACTION }</Span>
                <View style={tw`flex-row mt-5`}>
                    <Button
                        title={ labels.current?.CANCEL }
                        style={tw`w-full shrink py-2 bg-white border border-[${themes[theme].brand_primary}]`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-[${themes[theme].brand_primary}]`}
                        styleHoverText={tw`text-[${themes[theme].brand_primary}]`}
                        onPress={()=>{setModalActive(false)}}
                    />
                    <Button
                        title={ labels.current?.DELETE }
                        image={{ uri: require('../svg/delete_white.svg') }}
                        style={tw`ml-2 w-full shrink py-2 bg-red border border-red`}
                        styleHover={tw`bg-red`}
                        styleText={tw`text-white`}
                        styleHoverText={tw`text-white`}
                        onPress={()=>{
                            setPrimaryMsg('');

                            fetch(apiUrl, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    endpoint: 'pictures/delete', v: apiVersion,
                                    client_key: localStorage.getItem('clientKey'),
                                    device_id: localStorage.getItem('deviceID'),
                                    payload: {
                                        object_type: photoObjectType.current,
                                        object_id: yachtID,
                                        vessel_id: yachtID,
                                        id: modalAffectID,
                                    }
                                }),
                            })
                            .then((response) => {
                                return response.text().then(function(text) {
                                    const result = JSON.parse(text);
                                    if ( result.error )
                                    {
                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                        toast.show(result.error, {type: 'danger', duration:10000});
                                    }
                                    else
                                    {
                                        setModalAffectID('');
                                        setModalActive(false);
                                        setViewingAsset(false);
                                        toast.show((labels.current?.TOAST_DELETE).replace('{object}',labels.current?._ASSET), {type: 'success', duration:2000});

                                        setTimeout(()=>{
                                            setLastUpdate(now()); // forces app to refetch all account data
                                        }, 250);
                                    }
                                });
                            })
                            .catch(error => {
                                console.warn(error);
                                if ( ! window.navigator.onLine ) { toast.show('Internet connection failed. Please try again.', {type: 'danger', duration:10000}); }
                                else { toast.show('An error ocurred. See console log.', {type: 'danger', duration:10000}); }
                            });
                        }}
                    />
                </View>
            </Modal>
        </View>
    )
}

export default YachtPhotosView;
