import React from 'react';
import { View, ScrollView, Image, Pressable/*, Switch*/, Linking, Platform, useWindowDimensions } from 'react-native';
import { create, useDeviceContext } from 'twrnc';
import { PrimaryNav, Modal, Heading, Span, HorizontalRule, Button, TextInput, Notification, AttachmentUploadInput } from '../elements';
import { getInitials, getLabel, makeCopyright, getChangeLog, getCurrentRoute, now, now_MMDDYYYY, processAttachmentInput, prepareImportData, importData, do_openWidget } from '../helpers';
import { GlobalContext } from '../global';
import { useToast } from 'react-native-toast-notifications';
import { readString, jsonToCSV } from 'react-native-csv';

function AccountView ({route, navigation})
{
    const tw = create( require(`../../tailwind.config.js`) );    
    useDeviceContext(tw);
    const {height, width} = useWindowDimensions();
    const toast = useToast();
    
    let {
        version,
        apiUrl,
        apiVersion,
        settings,
        setLastUpdate,
        enabledFeatures,
        loggedIn, setLoggedIn,
        userLang, setUserLang,
        userDarkmode, setUserDarkmode,
        userFirstName, 
        userLastName,
        userEmail,
        userPhonePrefix,
        userPhone,
        userYachts,
        userNotificationSettings, setUserNotificationSettings,
        userLangID,
        navigationRef,
        themes, theme
    } = React.useContext(GlobalContext);

    let GLOBALS = React.useContext(GlobalContext);

    React.useEffect(()=> 
    {        
        if ( ! loggedIn && 'account' === getCurrentRoute(navigationRef) )
        {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            if ( urlParams.has('export') )
            {
                let attempted_view_args = localStorage.getItem('attempted-view-args');
                let new_attempted_view_args = {account:{export: true}};
                if ( urlParams.has('id') ) { new_attempted_view_args.account.id = parseInt(urlParams.get('id')); }
                if ( attempted_view_args ) { new_attempted_view_args = {...JSON.parse(attempted_view_args), ...new_attempted_view_args}; }
                localStorage.setItem('attempted-view-args', JSON.stringify(new_attempted_view_args));
            }
            else if ( urlParams.has('import') )
            {
                let attempted_view_args = localStorage.getItem('attempted-view-args');
                let new_attempted_view_args = {account:{import: true}};
                if ( urlParams.has('id') ) { new_attempted_view_args.account.id = parseInt(urlParams.get('id')); }
                if ( attempted_view_args ) { new_attempted_view_args = {...JSON.parse(attempted_view_args), ...new_attempted_view_args}; }
                localStorage.setItem('attempted-view-args', JSON.stringify(new_attempted_view_args));
            }
            else if ( urlParams.has('importexport') )
            {
                let attempted_view_args = localStorage.getItem('attempted-view-args');
                let new_attempted_view_args = {account:{importexport: true}};
                if ( urlParams.has('id') ) { new_attempted_view_args.account.id = parseInt(urlParams.get('id')); }
                if ( attempted_view_args ) { new_attempted_view_args = {...JSON.parse(attempted_view_args), ...new_attempted_view_args}; }
                localStorage.setItem('attempted-view-args', JSON.stringify(new_attempted_view_args));
            }
            navigation.navigate('loading');
        }
    },[loggedIn, navigation]);
    
    const labels = React.useRef(null);
    React.useEffect(()=> 
    {
        if ( ! labels.current )
        {
            labels.current = {
                ACCOUNT_DELETE_KEY: getLabel('ACCOUNT_DELETE_KEY'),
                ICON_ACCOUNT: getLabel('ICON_ACCOUNT'),
                EDIT: getLabel('EDIT'),
                ACCOUNT_PROFILE: getLabel('ACCOUNT_PROFILE'),
                ACCOUNT_SETTINGS: getLabel('ACCOUNT_SETTINGS'),
                ACCOUNT_FEEDBACK: getLabel('ACCOUNT_FEEDBACK'),
                ACCOUNT_TERMS: getLabel('ACCOUNT_TERMS'),
                ACCOUNT_PRIVACY: getLabel('ACCOUNT_PRIVACY'),
                ACCOUNT_DELETE: getLabel('ACCOUNT_DELETE'),
                DELETE_CONFIRM_TITLE: getLabel('DELETE_CONFIRM_TITLE'),
                ACCOUNT_LOGOUT: getLabel('ACCOUNT_LOGOUT'),
                CANCEL: getLabel('CANCEL'),
                ACCOUNT_LOGOUT: getLabel('ACCOUNT_LOGOUT'),
                ACCOUNT_DELETE_TITLE: getLabel('ACCOUNT_DELETE_TITLE'),
                ACCOUNT_DELETE_BODY: getLabel('ACCOUNT_DELETE_BODY'),
                ACCOUNT_DELETE_INSTRUCT: getLabel('ACCOUNT_DELETE_INSTRUCT'),
                PERMANENT_ACTION: getLabel('PERMANENT_ACTION'),
                CHANGELOG: getLabel('CHANGELOG'),
                NEXT: getLabel('NEXT'),
                BACK: getLabel('BACK'),
                DELETE: getLabel('DELETE'),
                EXPORT: getLabel('EXPORT'),
                ALL: getLabel('ALL'),
                SELECTED: getLabel('SELECTED'),
                EXPORT_DATA: getLabel('EXPORT_DATA'),
                EXPORT_SELECT_YACHTS: getLabel('EXPORT_SELECT_YACHTS'),
                EXPORT_SELECT_YACHTS_BODY: getLabel('EXPORT_SELECT_YACHTS_BODY'),
                EXPORT_DATA_DETAILS_TITLE: getLabel('EXPORT_DATA_DETAILS_TITLE'),
                EXPORT_DATA_DETAILS_BODY: getLabel('EXPORT_DATA_DETAILS_BODY'),
                EXPORT_SUCCESS: getLabel('EXPORT_SUCCESS'),
                VIEW: getLabel('VIEW'),
                CREW_MEMBERS: getLabel('CREW_MEMBERS'),
                ALERT_TITLES_SUCCESS: getLabel('ALERT_TITLES_SUCCESS'),
                IMPORT_DATA: getLabel('IMPORT_DATA'),
                IMPORT_CHOOSE_VESSEL_TITLE: getLabel('IMPORT_CHOOSE_VESSEL_TITLE'),
                IMPORT_CHOOSE_VESSEL_BODY: getLabel('IMPORT_CHOOSE_VESSEL_BODY'),
                IMPORT_CHOOSE_DATA_TITLE: getLabel('IMPORT_CHOOSE_DATA_TITLE'),
                IMPORT_CHOOSE_DATA_BODY: getLabel('IMPORT_CHOOSE_DATA_BODY'),
                IMPORT_CSV: getLabel('IMPORT_CSV'),
                IMPORT_DOWNLOAD_TEMPLATE_BODY: getLabel('IMPORT_DOWNLOAD_TEMPLATE_BODY'),
                IMPORT_DOWNLOAD_TEMPLATE: getLabel('IMPORT_DOWNLOAD_TEMPLATE'),
                IMPORT_SELECT_CSV: getLabel('IMPORT_SELECT_CSV'),
                IMPORT: getLabel('IMPORT'),
                IMPORT_FIELD_INVALID: getLabel('IMPORT_FIELD_INVALID'),
                ATTACHMENT_TOO_LARGE_BODY: getLabel('ATTACHMENT_TOO_LARGE_BODY'),
                ATTACHMENT_OPEN_ERROR: getLabel('ATTACHMENT_OPEN_ERROR'),
                SELECT: getLabel('SELECT'),
                ATTACHMENT_FILE: getLabel('ATTACHMENT_FILE'),
                COMPLETED: getLabel('COMPLETED'),
                NOTIFICATIONS: getLabel('NOTIFICATIONS'),
                SMS: getLabel('SMS'),
                EMAIL: getLabel('EMAIL'),
                PUSH: getLabel('PUSH'),
                UPDATE: getLabel('UPDATE'),
                ACCOUNT_NOTIFICATIONS_INSTRUCT: getLabel('ACCOUNT_NOTIFICATIONS_INSTRUCT'),
                OR: getLabel('OR'),
            };
        }
    },[]); 

    // eg) Help / Feedback | YachtWave v1.0.7 | Web Windows 10
    const versionInfo = ['Help%20/%20Feedback', '|', 'YachtWave%20'+version, '|', 'Web'].join('%20');
    const copyright = makeCopyright(version);
    const urls = {
        terms: 'https://www.yachtwave.com/policies-mobile-terms/',
        privacy: 'https://www.yachtwave.com/policies-mobile-privacy/',
        support: 'mailto:support@yachtwave.com?subject='+versionInfo,
    };
    const deleteMatch = labels.current?.ACCOUNT_DELETE_KEY;

    const [modalActive, setModalActive] = React.useState(false);
    const [deleteModalActive, setDeleteModalActive] = React.useState(false);
    const [deleteConfirm, setDeleteConfirm] = React.useState('');
    const [showChangeLog, setShowChangeLog] = React.useState(false);
    const [exportModalActive, setExportModalActive] = React.useState(false);
    const [exportYachtsAvailable, setExportYachtsAvailable] = React.useState([]);
    const [exportYachts, setExportYachts] = React.useState([]);
    const [exportSelectDetails, setExportSelectDetails] = React.useState(0);
    const [newExportValidation, doNewExportValidation] = React.useState(false);
    const [importModalActive, setImportModalActive] = React.useState(false);
    const [importYachtsAvailable, setImportYachtsAvailable] = React.useState([]);
    const [importYachts, setImportYachts] = React.useState([]);
    const [importSelectDetails, setImportSelectDetails] = React.useState(0);
/*new*/    const [importExportModalActive, setImportExportModalActive] = React.useState(false);
    const [newImportValidation, doNewImportValidation] = React.useState(false);
    const newAttachmentInput = React.useRef(null);
    const [attachmentData, setAttachmentData] = React.useState('');
    const attachmentFileNameInput = React.useRef(null);
    const [notificationModalActive, setNotificationModalActive] = React.useState(false);
    const [newNotificationSettingsValidation, doNewNotificationSettingsValidation] = React.useState(false);

    const importResourceTypeMap = {
        'Boat Details': 'vessels',
        'Mechanical': 'engines',
        'Inventory': 'inventory',
        'Equipment': 'equipment',
        'Maintenance': 'logs/maintenance',
        'Tasks': 'tasks',
        'Notes': 'notes'
    };
    const importSuccessRedirectMap = {
        'Boat Details': (yachtID)=>{ navigation.navigate('yacht', {yachtID:yachtID}); },
        'Mechanical': (yachtID)=>{ navigation.navigate('engines', {yachtID:yachtID}); },
        'Inventory': (yachtID)=>{ navigation.navigate('inventory', {yachtID:yachtID}); },
        'Equipment': (yachtID)=>{ navigation.navigate('equipment', {yachtID:yachtID}); },
        'Maintenance': (yachtID)=>{ navigation.navigate('logs', {yachtID:yachtID}); },
        'Tasks': (yachtID)=>{ navigation.navigate('yachtTasks', {yachtID:yachtID}); },
        'Notes': (yachtID)=>{ navigation.navigate('notes', {yachtID:yachtID}); }
    };

    const changelogRef = React.useRef(getChangeLog());
    const daysRef = React.useRef(1);
    const exportLinkRef = React.useRef('');
    const importLinkRef = React.useRef('');

    React.useEffect(()=> {

        let yachts = [];
        userYachts.forEach((yacht)=>{
            yachts.push({id: yacht.id, name: yacht.name, details:{'Boat Details': true,'Shares': true,'Mechanical': true,'Inventory': true,'Equipment': true,'Maintenance': true,'Tasks': true,'Notes': true,'Checklists': true}});
        });
        let this_exportYachtsAvailable = yachts;
        setExportYachtsAvailable(yachts);
        yachts = [];
        userYachts.forEach((yacht)=>{
            yachts.push({id: yacht.id, name: yacht.name, details:{'Boat Details': false,'Mechanical': false,'Inventory': false,'Equipment': false,'Maintenance': false,'Tasks': false,'Notes': false/*,'Checklists': false*/}});
        });
        let this_importYachtsAvailable = yachts;
        setImportYachtsAvailable(yachts);

        let importYachtsModalDefault = false;
        let exportYachtsModalDefault = false;
        let importExportYachtsModalDefault = false;
        let exportModalYachts = [];
        let importModalYacht = 0;

        // export
        if ( route.params?.export )
        {
            exportYachtsModalDefault = true;
            if ( route.params?.id ) { exportModalYachts = [parseInt(route.params.id)]; }
        }
        // import
        else if ( route.params?.import )
        {
            importYachtsModalDefault = true;
            if ( route.params?.id ) { importModalYacht = parseInt(route.params.id); }
        }
        // import OR export
        else if ( route.params?.importexport )
        {
            importExportYachtsModalDefault = true;
            if ( route.params?.id ) { importModalYacht = parseInt(route.params.id); exportModalYachts = [parseInt(route.params.id)]; }
        }

        // export or import via attempted args
        else if ( localStorage.getItem('attempted-view-args') )
        {
            let attempted_view_args = JSON.parse(localStorage.getItem('attempted-view-args'));
            // export
            if ( attempted_view_args?.account?.export )
            {
                exportYachtsModalDefault = true;
                if ( attempted_view_args?.account?.id ) { exportModalYachts = [parseInt(attempted_view_args.account.id)]; }
            }
            // import
            else if ( attempted_view_args?.account?.import )
            {
                importYachtsModalDefault = true;
                if ( attempted_view_args?.account?.id ) { importModalYacht = parseInt(attempted_view_args.account.id); }
            }
            // import OR export
            else if ( attempted_view_args?.account?.importexport )
            {
                importExportYachtsModalDefault = true;
                if ( attempted_view_args?.account?.id ) { importModalYacht = parseInt(attempted_view_args.account.id); exportModalYachts = [parseInt(attempted_view_args.account.id)]; }
            }
        }

        let found_yachts = [];
        let found_export_yachts = [];
        if ( exportModalYachts.length > 0 )
        {
            found_export_yachts = this_exportYachtsAvailable.filter(yacht => exportModalYachts.includes(yacht.id));
            setExportYachts(found_export_yachts);
        }
        if ( importModalYacht )
        {
            let found_yacht = this_importYachtsAvailable.find(yacht => yacht.id == importModalYacht);
            found_yachts.push(found_yacht);
            if ( found_yachts ) { setImportYachts(found_yachts); }
        }

        if ( exportYachtsModalDefault )
        {
            if ( exportModalYachts && found_export_yachts.length > 0 )
            {
                setExportSelectDetails(1);
            }
            setExportModalActive(true);
        }
        else if ( importYachtsModalDefault )
        {
            if ( importModalYacht && found_yachts.length > 0 )
            {
                setImportSelectDetails(1);
            }
            setImportModalActive(true);
        }
        else if ( importExportYachtsModalDefault )
        {
            if ( importModalYacht && exportModalYachts.length > 0 && found_export_yachts.length > 0 && found_yachts.length > 0 )
            {
                setImportExportModalActive(true);
            }
        }
    },[route.params, userYachts, setExportYachts, setImportYachts, setExportYachtsAvailable, setImportYachtsAvailable]);

    const toggleSelectedYacht = React.useCallback((id)=>{
        // if the yacht is already in exportYachts, remove it
        if ( exportYachts.find(yacht => yacht.id === id) )
        {
            let yachts = exportYachts.filter(yacht => yacht.id !== id);
            setExportYachts(yachts);
        }
        else
        {
            // find the yacht id in exportYachtsAvailable, and add that object to exportYachts
            let yacht = exportYachtsAvailable.find(yacht => yacht.id === id);
            setExportYachts([...exportYachts, yacht]);
        }
    },[exportYachts, exportYachtsAvailable]);

    const toggleDetailSelection = (yachtId, detailName) => {
        setExportYachts(currentYachts =>
            currentYachts.map(yacht =>
                yacht.id === yachtId
                  ? { ...yacht, details: { ...yacht.details, [detailName]: !yacht.details[detailName] } }
                  : yacht
            )
        );
    };

    const toggleAllDetailsSelection = (yachtId) => {
        setExportYachts(currentYachts =>
            currentYachts.map(yacht => {
                if (yacht.id === yachtId) {
                    const allSelected = Object.values(yacht.details).every(value => value);
                    const newDetails = Object.keys(yacht.details).reduce((details, key) => {
                        details[key] = !allSelected;
                        return details;
                    }, {});
                    return { ...yacht, details: newDetails };
                }
                return yacht;
            })
        );
    };

    const toggleSelectedImportYacht = React.useCallback((id)=>{
        let yacht = importYachtsAvailable.find(yacht => yacht.id === id);
        setImportYachts([yacht]);
    },[importYachts, importYachtsAvailable]);

    const toggleImportDetailSelection = (yachtId, detailName) => {
        setImportYachts(currentYachts =>
            /*currentYachts.map(yacht =>
                yacht.id === yachtId
                  ? { ...yacht, details: { ...yacht.details, [detailName]: !yacht.details[detailName] } }
                  : yacht
            )*/

            // loop through yacht details and mark all false, but detailName mark true
            currentYachts.map(yacht =>
                yacht && yacht?.id && yacht.id === yachtId
                  ? { ...yacht, details: Object.keys(yacht.details).reduce((details, key) => {
                        details[key] = key === detailName;
                        return details;
                    }, {}) }
                  : yacht
            )
        );
    };

    const generateCSVandDownload = React.useCallback((importYachts) =>
    {
        const importType = Object.entries(importYachts[0].details).map(([detailName, isSelected]) => {
                if ( isSelected ) { return detailName; }
            }).filter(v => v)[0];
        let dataString = '';
        let dataJSON = [];
        let jsonString = '';
        let fileName = 'Template';

        switch (importType)
        {
            case 'Boat Details':
                dataJSON = [
                    ["HIN", ""],
                    ["Year", ""],
                    ["Manufacturer", ""],
                    ["Brand", ""],
                    ["Model", ""],
                    ["Country", ""],
                    ["Port", ""],
                    ["USCG Number", ""],
                    ["Registration Number", ""],
                    ["IMO", ""],
                    ["MMSI", ""],
                    ["Callsign", ""],
                    ["EPIRB", ""],
                    ["Use Metric Units? (m/tons vs ft/lbs)", "No"],
                    ["Beam", ""],
                    ["Length", ""],
                    ["Draft", ""],
                    ["Air Draft", ""],
                    ["Displacement", ""],
                    ["Shorepower", ""],
                    ["Shorepower Qty", ""],
                    ["Voltage", ""],
                    ["Hull Type", ""],
                    ["Hull Material", ""]
                ];
                jsonString = jsonToCSV(dataJSON);
                fileName = 'Boat Details Template';
                break;

            case 'Mechanical':
                dataJSON = [
                    ["Type (Required)", ""],
                    ["Location (Required)", ""],
                    ["Manufacturer", ""],
                    ["Year", ""],
                    ["Model", ""],
                    ["Serial Number", ""],
                    ["Part Number", ""],
                    ["Fuel Type", ""],
                    ["Horsepower", ""]
                ];
                jsonString = jsonToCSV(dataJSON);
                fileName = 'Mechanical Template';
                break;

            case 'Inventory':
                dataJSON = [
                    ["Name (Required)", ""],
                    ["Type (Required)", ""],
                    ["Location (Required)", ""],
                    ["Position", ""],
                    ["Quantity Required", ""],
                    ["Quantity On-hand", ""],
                    ["Part Number", ""],
                    ["URL", ""],
                    ["Notes", ""]
                ];
                jsonString = jsonToCSV(dataJSON);
                fileName = 'Inventory Template';
                break;

            case 'Equipment':
                dataJSON = [
                    ["Type (Required)", ""],
                    ["Location (Required)", ""],
                    ["Manufacturer (Required)", ""],
                    ["Model (Required)", ""],
                    ["Position", ""],
                    ["Serial Number", ""],
                    ["Deployed Date (MM/DD/YYYY)", ""],
                    ["Notes", ""]
                ];
                jsonString = jsonToCSV(dataJSON);
                fileName = 'Equipment Template';
                break;

            case 'Maintenance':
                dataJSON = [
                    ["Log Title (Required)", ""],
                    ["Type (Required)", ""],
                    ["Date (MM/DD/YYYY) (Required)", now_MMDDYYYY()],
                    ["Equipment Location", ""],
                    ["Engine Hours", ""],
                    ["Notes", ""]
                ];
                jsonString = jsonToCSV(dataJSON);
                fileName = 'Maintenance Template';
                break;

            case 'Tasks':
                dataJSON = [
                    ["Name (Required)", ""],
                    ["Due Date (MM/DD/YYYY) (Defaults to Today)", ""],
                    ["Priority (Low, Medium, High, Critical) (Defaults to Medium)", ""],
                    ["Due Hours", ""],
                    ["Details", ""],
                    ["Recurring? (Yes, No)", "No"],
                    ["Recurring Inteval (1-99)", ""],
                    ["Recurring Interval Type (Hour(s), Day(s), Week(s), Month(s), Year(s))", ""]
                ];
                jsonString = jsonToCSV(dataJSON);
                fileName = 'Tasks Template';
                break;

            case 'Notes':
                dataJSON = [
                    ["Name (Required)", ""],
                    ["Type (Required)", ""],
                    ["Details", ""]
                ];
                jsonString = jsonToCSV(dataJSON);
                fileName = 'Notes Template';
                break;
        }

        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(jsonString);
        hiddenElement.target = '_blank';
        hiddenElement.download = fileName + '.csv';
        hiddenElement.click();
    },[]);

    return (
        <View label="accountView" style={tw`bg-gray0 h-screen md:flex-row overflow-hidden max-w-screen`}>

            <PrimaryNav navigation={navigation} setLoggedIn={setLoggedIn} />
        
            <View style={tw`bg-[${themes[theme].brand_primary}] h-8 md:h-full md:w-10 z-0 -mb-3 md:mb-0 md:-mr-3`}>
                <View style={tw`bg-gray0 h-full w-full rounded-t-3xl md:rounded-l-3xl md:rounded-r-0 z-10 md:shadow-black md:shadow-opacity-10 md:shadow-radius-4`}></View>
            </View>

            <View style={[tw`bg-gray0 flex-grow md:pr-8 md:pl-4 z-20`, {width: tw.prefixMatch('md') ? 'calc(100% - 320px)' : '100%', height: tw.prefixMatch('md') ? '100%' : 'calc(100% - 112px)'}]}>
                <View style={tw`h-13 px-5 pb-5 md:py-0 md:px-0 md:h-24 lg:h-33 flex-row md:items-center z-10`}>
                    <Heading style={tw`mt-1.5 md:mt-0 leading-4 mb-0`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>
                        { labels.current?.ICON_ACCOUNT }
                    </Heading>
                </View>

                <View style={tw`shrink`}>
                    <ScrollView
                        style={{width:'100%',overflowX:'hidden',marginBottom:'32px'}}
                        contentContainerStyle={tw`w-full justify-start px-5 md:px-0`}
                    >
                        <Span
                            style={tw`text-2xl text-white bg-[${themes[theme].brand_primary}] w-16 h-16 leading-[64px] text-center font-bold rounded-full`}
                        >{getInitials(userFirstName+' '+userLastName)}</Span>

                        <View style={tw`mt-4 flex-row`}>
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/'+theme+'/user.svg') }}
                                resizeMode="contain"
                                style={tw`max-h-full max-w-full h-6 w-6 mr-4`}
                            />
                            <Span>{userFirstName+' '+userLastName}</Span>
                        </View>

                        <View style={tw`mt-4 flex-row`}>
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/'+theme+'/email_blue.svg') }}
                                resizeMode="contain"
                                style={tw`max-h-full max-w-full h-6 w-6 mr-4`}
                            />
                            <Span>{userEmail}</Span>
                        </View>

                        <View style={tw`mt-4 flex-row`}>
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/'+theme+'/phone.svg') }}
                                resizeMode="contain"
                                style={tw`max-h-full max-w-full h-6 w-6 mr-4`}
                            />
                            <Span>+{userPhonePrefix+' '+userPhone}</Span>
                        </View>

                        <Pressable
                            style={tw`mt-4 flex-row items-center`}
                            onPress={()=>{ navigation.navigate('editAccount') }}
                        >
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/'+theme+'/edit_blue.svg') }}
                                resizeMode="contain"
                                style={tw`max-h-full max-w-full h-6 w-6 mr-4`}
                            />
                            <Span style={tw`text-[${themes[theme].brand_primary}] uppercase font-bold text-sm tracking-widest`}>{ labels.current?.EDIT } { labels.current?.ACCOUNT_PROFILE }</Span>
                        </Pressable>

                        <HorizontalRule style={tw`mt-8 mb-7 -left-5 w-screen md:hidden`} />

                        <Heading style={tw`mt-0 md:mt-16 -mb-1`} hlevel={2} styleHlevel={tw.prefixMatch('md') ? 3 : 4}>{ labels.current?.ACCOUNT_SETTINGS }</Heading>

                        { /* <Pressable
                            style={tw`mt-2 w-full md:w-78 flex-row items-center relative`}
                            onPress={()=>{console.log('Editing Language'); setUserLang('English')}}
                        >
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/'+theme+'/language.svg') }}
                                resizeMode="contain"
                                style={tw`max-h-full max-w-full h-6 w-6 mr-4`}
                            />
                            <Span style={tw``}>Language</Span>
                            <Span style={tw`text-gray6 absolute right-6`}>{userLang}</Span>
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/'+theme+'/arrow_forward_ios.svg') }}
                                resizeMode="contain"
                                style={tw`max-h-full max-w-full h-4 w-4 absolute right-0`}
                            />
                        </Pressable>

                        <Pressable
                            style={tw`mt-4 w-full md:w-78 flex-row items-center relative`}
                            onPress={()=>{console.log('Editing Dark Mode'); setUserDarkmode( userDarkmode ? false : true )}}
                        >
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/'+theme+'/dark mode.svg') }}
                                resizeMode="contain"
                                style={tw`max-h-full max-w-full h-6 w-6 mr-4`}
                            />
                            <Span style={tw``}>Dark Mode</Span>
                            <View style={tw`absolute right-0`}>
                                <Switch 
                                    value={userDarkmode}
                                    thumbColor={tw.color('white')}
                                    activeThumbColor={themes[theme].brand_primary}
                                    trackColor={tw.color('gray2')}
                                    activeTrackColor={tw.color('gray2')}
                                />
                            </View>
                        </Pressable>

                        */ }

                        <Pressable
                            style={tw`mt-4 w-full md:w-78 flex-row items-center relative`}
                            onPress={()=>{ window.OpenWidget.call('maximize'); }}
                        >
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/'+theme+'/question.svg') }}
                                resizeMode="contain"
                                style={tw`max-h-full max-w-full h-6 w-6 mr-4`}
                            />
                            <Span style={tw``}>{ labels.current?.ACCOUNT_FEEDBACK }</Span>
                        </Pressable>

                        <Pressable
                            style={tw`mt-4 w-full md:w-78 flex-row items-center relative`}
                            onPress={()=>{ Linking.openURL(urls.terms) }}
                        >
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/'+theme+'/rss.svg') }}
                                resizeMode="contain"
                                style={tw`max-h-full max-w-full h-6 w-6 mr-4`}
                            />
                            <Span style={tw``}>{ labels.current?.ACCOUNT_TERMS }</Span>
                        </Pressable>

                        <Pressable
                            style={tw`mt-4 w-full md:w-78 flex-row items-center relative`}
                            onPress={()=>{ Linking.openURL(urls.privacy) }}
                        >
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/'+theme+'/eye.svg') }}
                                resizeMode="contain"
                                style={tw`max-h-full max-w-full h-6 w-6 mr-4`}
                            />
                            <Span style={tw``}>{ labels.current?.ACCOUNT_PRIVACY }</Span>
                        </Pressable>

                        <Pressable
                            style={tw`mt-4 w-full md:w-78 flex-row items-center relative -mb-1`}
                            onPress={()=>{ setShowChangeLog(true) }}
                        >
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/'+theme+'/generalinfo.svg') }}
                                resizeMode="contain"
                                style={tw`max-h-full max-w-full h-6 w-6 mr-4`}
                            />
                            <Span style={tw``}>{ labels.current?.CHANGELOG }</Span>
                        </Pressable>

                        <View
                            style={tw`mt-1 w-full md:w-78 flex-row items-center relative`}
                        >
                            <Span style={tw``}>&nbsp;</Span>
                        </View>

                        <Pressable
                            style={tw`mt-4 w-full md:w-78 flex-row items-center relative`}
                            onPress={()=>{ setNotificationModalActive(true); }}
                        >
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/'+theme+'/notifications_blue.svg') }}
                                resizeMode="contain"
                                style={tw`max-h-full max-w-full h-6 w-6 mr-4`}
                            />
                            <Span style={tw``}>{ labels.current?.NOTIFICATIONS }</Span>
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/'+theme+'/arrow_forward_ios.svg') }}
                                resizeMode="contain"
                                style={tw`max-h-full max-w-full h-4 w-4 absolute right-0`}
                            />
                        </Pressable>

                        { true === enabledFeatures.import &&
                            <Pressable
                                style={tw`mt-4 w-full md:w-78 flex-row items-center relative`}
                                onPress={()=>{ setImportModalActive(true) }}
                            >
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('../svg/'+theme+'/import.svg') }}
                                    resizeMode="contain"
                                    style={tw`max-h-full max-w-full h-6 w-5.5 mr-4`}
                                />
                                <Span style={tw``}>{ labels.current?.IMPORT_DATA }</Span>
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('../svg/'+theme+'/arrow_forward_ios.svg') }}
                                    resizeMode="contain"
                                    style={tw`max-h-full max-w-full h-4 w-4 absolute right-0`}
                                />
                            </Pressable>
                        }

                        { true === enabledFeatures.export &&
                            <Pressable
                                style={tw`mt-4 w-full md:w-78 flex-row items-center relative`}
                                onPress={()=>{ setExportModalActive(true) }}
                            >
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('../svg/'+theme+'/export.svg') }}
                                    resizeMode="contain"
                                    style={tw`max-h-full max-w-full h-6 w-6 mr-4`}
                                />
                                <Span style={tw``}>{ labels.current?.EXPORT_DATA }</Span>
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('../svg/'+theme+'/arrow_forward_ios.svg') }}
                                    resizeMode="contain"
                                    style={tw`max-h-full max-w-full h-4 w-4 absolute right-0`}
                                />
                            </Pressable>
                        }

                        <Pressable
                            style={tw`mt-4 w-full md:w-78 flex-row items-center relative`}
                            onPress={()=>{ setDeleteModalActive(true) }}
                        >
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/delete account_red.svg') }}
                                resizeMode="contain"
                                style={tw`max-h-full max-w-full h-6 w-6 mr-4`}
                            />
                            <Span style={tw``}>{ labels.current?.ACCOUNT_DELETE }</Span>
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/'+theme+'/arrow_forward_ios.svg') }}
                                resizeMode="contain"
                                style={tw`max-h-full max-w-full h-4 w-4 absolute right-0`}
                            />
                        </Pressable>

                        <View
                            style={tw`mt-1 w-full md:w-78 flex-row items-center relative`}
                        >
                            <Span style={tw``}>&nbsp;</Span>
                        </View>

                        <View
                            style={tw`mt-4 w-full md:w-78 flex-row items-center relative`}
                        >
                            <Span style={tw`text-sm`}>{copyright}</Span>
                        </View>

                        <HorizontalRule style={tw`mt-4 mb-6 -left-5 w-screen md:hidden`} />

                        <View style={tw`md:hidden`}>
                            <Button 
                                onPress={()=>{setModalActive(true)}}
                                style={tw`py-4 mb-6`}
                                styleText={tw`text-sm tracking-1`}
                                title={ labels.current?.ACCOUNT_LOGOUT }
                                image={{ uri: require('../svg/logout.svg') }}
                            />
                        </View>
                    </ScrollView>
                </View>
            </View>

            <Modal
                active={modalActive}
                setActive={setModalActive}
            >
                <Heading hlevel={2} styleHlevel={3}>{ labels.current?.ACCOUNT_LOGOUT }</Heading>
                <Span>{ labels.current?.DELETE_CONFIRM_TITLE }</Span>
                <View style={tw`flex-row mt-5`}>
                    <Button
                        title={ labels.current?.CANCEL }
                        style={tw`w-full shrink py-2 bg-white border border-[${themes[theme].brand_primary}]`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-[${themes[theme].brand_primary}]`}
                        styleHoverText={tw`text-[${themes[theme].brand_primary}]`}
                        onPress={()=>{setModalActive(false)}}
                    />
                    <Button
                        title={ labels.current?.ACCOUNT_LOGOUT }
                        image={{ uri: require('../svg/logout.svg') }}
                        style={tw`ml-2 w-full shrink py-2`}
                        styleText={tw`text-white`}
                        styleHoverText={tw`text-white`}
                        onPress={()=>{
                            fetch(apiUrl, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    endpoint: 'user/logout', v: apiVersion,
                                    client_key: localStorage.getItem('clientKey'),
                                    device_id: localStorage.getItem('deviceID')
                                }),
                            })
                            .then((response) => {
                                return response.text().then(function(text) {
                                    const result = JSON.parse(text);
                                    if ( result.error ) 
                                    {
                                        console.warn(result.error);
                                    }
                                    else 
                                    {
                                        if ( typeof window.singularSdk === 'function' ) {
                                            window.singularSdk.logout();
                                        }
                                    }
                                });
                            })
                            .catch(error => {
                                console.warn(error);
                            });
                            
                            setModalActive(false);
                            // preserve 'labels' & 'deviceID'
                            let labels_ver = localStorage.getItem('labels_ver');
                            let labels = localStorage.getItem('labels');
                            let deviceID = localStorage.getItem('deviceID');
                            localStorage.clear();
                            if ( labels_ver ) { localStorage.setItem( 'labels_ver', labels_ver ); }
                            if ( labels ) { localStorage.setItem( 'labels', labels ); }
                            if ( deviceID ) { localStorage.setItem( 'deviceID', deviceID ); }
                            setLoggedIn(false);
                        }}
                    />
                </View>
            </Modal>            

            <Modal
                active={deleteModalActive}
                setActive={setDeleteModalActive}
            >   
                <Image
                    accessibilityHidden={true}
                    source={{ uri: require('../svg/big_alert.svg') }}
                    resizeMode="contain"
                    style={tw`h-16 w-16`}
                />
                <Heading hlevel={2} styleHlevel={3}>{ labels.current?.ACCOUNT_DELETE_TITLE }</Heading>
                <Span>{ labels.current?.ACCOUNT_DELETE_BODY }</Span>
                <Span style={tw`text-red mt-2`}>{ labels.current?.PERMANENT_ACTION }</Span>
                <TextInput
                    style={tw`mt-2`}
                    placeholder={ labels.current?.ACCOUNT_DELETE_INSTRUCT }
                    onChangeText={(value)=>{setDeleteConfirm(value)}}
                />
                <View style={tw`flex-row mt-5`}>
                    <Button
                        title={ labels.current?.CANCEL }
                        style={tw`w-full shrink py-2 bg-white border border-[${themes[theme].brand_primary}]`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-[${themes[theme].brand_primary}]`}
                        styleHoverText={tw`text-[${themes[theme].brand_primary}]`}
                        onPress={()=>{setDeleteModalActive(false)}}
                    />
                    <Button
                        disabled={ deleteMatch === deleteConfirm ? false : true }
                        title={ labels.current?.DELETE }
                        image={ deleteMatch === deleteConfirm ? { uri: require('../svg/delete_white.svg') } : { uri: require('../svg/delete_lightgray.svg') } }
                        style={[tw`ml-2 w-full shrink py-2 bg-red border border-gray3`, deleteMatch === deleteConfirm && tw`border-red`]}
                        styleHover={tw`bg-red`}
                        styleText={tw`text-white`}
                        styleHoverText={tw`text-white`}
                        onPress={()=>{
                            fetch(apiUrl, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    endpoint: 'user/delete', v: apiVersion,
                                    client_key: localStorage.getItem('clientKey'),
                                    device_id: localStorage.getItem('deviceID')
                                }),
                            })
                            .then((response) => {
                                return response.text().then(function(text) {
                                    const result = JSON.parse(text);
                                    if ( result.error ) 
                                    {
                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                        console.warn(result.error);
                                    }
                                    else 
                                    {
                                    }
                                });
                            })
                            .catch(error => {
                                console.warn(error);
                            });
                            
                            setDeleteModalActive(false);
                            let last_ver = localStorage.getItem('last_ver');
                            let labels_ver = localStorage.getItem('labels_ver');
                            let labels = localStorage.getItem('labels');
                            localStorage.clear();
                            if ( last_ver ) { localStorage.setItem( 'last_ver', last_ver ); }
                            if ( labels_ver ) { localStorage.setItem( 'labels_ver', labels_ver ); }
                            if ( labels ) { localStorage.setItem( 'labels', labels ); }
                            setLoggedIn(false);
                        }}
                    />
                </View>
            </Modal>

            <Modal
                active={notificationModalActive}
                setActive={setNotificationModalActive}
                doWhileExit={()=>{}}
            >
                <View>
                    <Heading hlevel={2} styleHlevel={3}>{ labels.current?.NOTIFICATIONS }</Heading>
                    <Span>{ labels.current?.ACCOUNT_NOTIFICATIONS_INSTRUCT }</Span>
                    <ScrollView
                        style={{width: '100%', overflowX: 'visible', maxHeight: 228, marginTop: 16}}
                        contentContainerStyle={tw`w-full min-h-full justify-start flex-col bg-gray0 rounded-md p-4`}
                    >
                        {'undefined' !== typeof userNotificationSettings && Object.entries(userNotificationSettings).map(([setting, value], index) => {
                            return (
                                <Pressable
                                    key={index}
                                    style={tw`flex-row justify-start py-1.5 items-center`}
                                    onPress={() => {
                                        setUserNotificationSettings((prevSettings) => ({
                                            ...prevSettings,
                                            [setting]: !prevSettings[setting],
                                        }));
                                    }}
                                >
                                    <Image
                                        accessibilityLabel={value ? labels.current?.SELECTED : false}
                                        source={
                                            value
                                                ? { uri: require('../svg/'+theme+'/checkbox_active.svg') }
                                                : { uri: require('../svg/checkbox.svg') }
                                        }
                                        resizeMode="contain"
                                        style={tw`h-7.5 w-7.5 mr-2`}
                                    />
                                    <Span>{
                                        ('receives_notification_sms' === setting && labels.current?.SMS+' '+labels.current?.NOTIFICATIONS)
                                        || ('receives_notification_emails' === setting && labels.current?.EMAIL+' '+labels.current?.NOTIFICATIONS)
                                        || ('receives_notification_push' === setting && labels.current?.PUSH+' '+labels.current?.NOTIFICATIONS)
                                    }</Span>
                                </Pressable>
                            );
                        })}
                    </ScrollView>
                    <View style={tw`flex-row mt-5`}>
                        <Button
                            title={ labels.current?.UPDATE }
                            style={[tw`w-full shrink bg-[${themes[theme].brand_primary}] border border-[${themes[theme].brand_primary}]`]}
                            styleHover={tw`bg-[${themes[theme].brand_primary}]`}
                            styleText={tw`text-white`}
                            styleHoverText={tw`text-white`}
                            validateGroup={newNotificationSettingsValidation}
                            onPress={()=>{

                                doNewNotificationSettingsValidation(true);

                                fetch(apiUrl, {
                                    method: 'POST',
                                    cache: 'no-cache',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        endpoint: 'user/update', v: apiVersion,
                                        client_key: localStorage.getItem('clientKey'),
                                        device_id: localStorage.getItem('deviceID'),
                                        payload: {
                                            first_name: userFirstName,
                                            last_name: userLastName,
                                            receives_notification_emails: userNotificationSettings.receives_notification_emails,
                                            receives_notification_sms: 0 /*userNotificationSettings.receives_notification_sms*/,
                                            receives_notification_push: 0 /*userNotificationSettings.receives_notification_push*/,
                                            lang_id: userLangID,
                                        }
                                    }),
                                })
                                .then((response) => {
                                    return response.text().then(function(text) {
                                        const result = JSON.parse(text);
                                        if ( result.error )
                                        {
                                            if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                            toast.show(result.error, {type: 'danger', duration:10000});
                                            doNewNotificationSettingsValidation(false);
                                        }
                                        else
                                        {
                                            setTimeout(()=>{doNewNotificationSettingsValidation(false); setNotificationModalActive(false);},500);
                                            // update localstorage
                                            let user = JSON.parse(localStorage.getItem('user'));
                                            user.receives_notification_emails = userNotificationSettings.receives_notification_emails;
                                            user.receives_notification_sms = userNotificationSettings.receives_notification_sms;
                                            user.receives_notification_push = userNotificationSettings.receives_notification_push;
                                            localStorage.setItem('user', JSON.stringify(user));
                                        }
                                    });
                                })
                                .catch(error => {
                                    console.warn(error);
                                    doNewNotificationSettingsValidation(false);
                                    if ( ! window.navigator.onLine ) { toast.show('Internet connection failed. Please try again.', {type: 'danger', duration:10000}); }
                                    else { toast.show('An error ocurred. See console log.', {type: 'danger', duration:10000}); }
                                });
                            }}
                        />
                    </View>
                </View>
            </Modal>

            { true === enabledFeatures.export && true === enabledFeatures.import &&
                <Modal
                    active={importExportModalActive}
                    setActive={setImportExportModalActive}
                    doWhileExit={()=>{
                        // remove account.export from attempted_view_args
                        let attempted_view_args = JSON.parse(localStorage.getItem('attempted-view-args'));
                        delete attempted_view_args?.account?.importexport;
                        delete attempted_view_args?.account?.id;
                        localStorage.setItem('attempted-view-args', JSON.stringify(attempted_view_args));
                        setExportSelectDetails(0);
                        setExportModalActive(false);
                        setImportSelectDetails(0);
                        setImportModalActive(false);
                    }}
                >
                    <View>
                        <Heading hlevel={2} styleHlevel={3}>{ labels.current?.IMPORT } { labels.current?.OR } { labels.current?.EXPORT }?</Heading>
                        <View style={tw`flex-row mt-5`}>
                            <Button
                                title={ labels.current?.IMPORT }
                                // image={ deleteMatch === deleteConfirm ? { uri: require('../svg/delete_white.svg') } : { uri: require('../svg/delete_lightgray.svg') } }
                                style={[tw`ml-2 w-full shrink py-2 bg-[${themes[theme].brand_primary}] border border-gray3`, exportYachts.length > 0 && tw`border-[${themes[theme].brand_primary}]`]}
                                styleHover={tw`bg-[${themes[theme].brand_primary}]`}
                                styleText={tw`text-white`}
                                styleHoverText={tw`text-white`}
                                onPress={()=>{setExportYachts([]); setImportSelectDetails(1); setImportModalActive(true); setImportExportModalActive(false);}}
                            />
                            <Button
                                title={ labels.current?.EXPORT }
                                // image={ deleteMatch === deleteConfirm ? { uri: require('../svg/delete_white.svg') } : { uri: require('../svg/delete_lightgray.svg') } }
                                style={[tw`ml-2 w-full shrink py-2 bg-[${themes[theme].brand_primary}] border border-gray3`, exportYachts.length > 0 && tw`border-[${themes[theme].brand_primary}]`]}
                                styleHover={tw`bg-[${themes[theme].brand_primary}]`}
                                styleText={tw`text-white`}
                                styleHoverText={tw`text-white`}
                                onPress={()=>{setImportYachts([]); setExportSelectDetails(1); setExportModalActive(true); setImportExportModalActive(false);}}
                            />
                        </View>
                    </View>
                </Modal>
            }

            { true === enabledFeatures.export &&
                <Modal
                    active={exportModalActive}
                    setActive={setExportModalActive}
                    doWhileExit={()=>{
                        // remove account.export from attempted_view_args
                        let attempted_view_args = JSON.parse(localStorage.getItem('attempted-view-args'));
                        delete attempted_view_args?.account?.export;
                        delete attempted_view_args?.account?.id;
                        localStorage.setItem('attempted-view-args', JSON.stringify(attempted_view_args));
                        setTimeout(()=>{setExportSelectDetails(0); setExportYachts([]);},500);
                    }}
                >
                    { 0 === exportSelectDetails ?
                        <View>
                            <Heading hlevel={2} styleHlevel={3}>{ labels.current?.EXPORT_SELECT_YACHTS }</Heading>
                            <Span>{ labels.current?.EXPORT_SELECT_YACHTS_BODY }</Span>
                            <ScrollView
                                style={{width: '100%', overflowX: 'visible', maxHeight: 228, marginTop: 16}}
                                contentContainerStyle={tw`w-full min-h-full justify-start flex-col bg-gray0 rounded-md p-4`}
                            >
                                { exportYachtsAvailable.map((yacht, i)=>{
                                    return <Pressable key={i} style={tw`flex-row justify-start py-1.5 items-center`}
                                        onPress={()=>{ toggleSelectedYacht(yacht.id); }}
                                    >
                                        <Image
                                            accessibilityLabel={ exportYachts.find(y=>y.id === yacht.id) ? labels.current?.SELECTED : false }
                                            source={ exportYachts.find(y=>y.id === yacht.id) ? {uri: require('../svg/'+theme+'/checkbox_active.svg')} : {uri: require('../svg/checkbox.svg')} }
                                            resizeMode="contain"
                                            style={tw`h-7.5 w-7.5 mr-2`}
                                        />
                                        <Span>{yacht.name}</Span>
                                    </Pressable>
                                })}
                            </ScrollView>
                            <View style={tw`flex-row mt-5`}>
                                <Button
                                    title={ labels.current?.CANCEL }
                                    style={tw`w-full shrink py-2 bg-white border border-[${themes[theme].brand_primary}]`}
                                    styleHover={tw`bg-white`}
                                    styleText={tw`text-[${themes[theme].brand_primary}]`}
                                    styleHoverText={tw`text-[${themes[theme].brand_primary}]`}
                                    onPress={()=>{
                                        setExportModalActive(false);
                                        // remove account.export from attempted_view_args
                                        let attempted_view_args = JSON.parse(localStorage.getItem('attempted-view-args'));
                                        delete attempted_view_args?.account?.export;
                                        localStorage.setItem('attempted-view-args', JSON.stringify(attempted_view_args));
                                        setTimeout(()=>{setExportSelectDetails(0); setExportYachts([]);},500);
                                    }}
                                />
                                <Button
                                    disabled={ exportYachts.length > 0 ? false : true }
                                    title={ labels.current?.NEXT }
                                    // image={ deleteMatch === deleteConfirm ? { uri: require('../svg/delete_white.svg') } : { uri: require('../svg/delete_lightgray.svg') } }
                                    style={[tw`ml-2 w-full shrink py-2 bg-[${themes[theme].brand_primary}] border border-gray3`, exportYachts.length > 0 && tw`border-[${themes[theme].brand_primary}]`]}
                                    styleHover={tw`bg-[${themes[theme].brand_primary}]`}
                                    styleText={tw`text-white`}
                                    styleHoverText={tw`text-white`}
                                    onPress={()=>{setExportSelectDetails(1)}}
                                />
                            </View>
                        </View>
                    : ( 1 === exportSelectDetails ?
                        <View>
                            <Heading hlevel={2} styleHlevel={3}>{ labels.current?.EXPORT_DATA_DETAILS_TITLE }</Heading>
                            <Span>{ labels.current?.EXPORT_DATA_DETAILS_BODY }</Span>
                            <ScrollView
                                style={{width: '100%', overflowX: 'visible', maxHeight: 228, marginTop: 16}}
                                contentContainerStyle={tw`w-full min-h-full justify-start flex-col bg-gray0 rounded-md p-4`}
                            >
                                {exportYachts.map((yacht, index) => (
                                    <View key={yacht.id}>
                                        <Heading hlevel={3} styleHlevel={4} style={tw`mt-4 ${!index && 'mt-0'}`}>{yacht.name}</Heading>
                                        <Pressable
                                            style={tw`flex-row justify-start py-1.5 items-center`}
                                            onPress={() => toggleAllDetailsSelection(yacht.id)}
                                        >
                                            <Image
                                                accessibilityLabel={Object.values(yacht.details).every(v => v) ? labels.current?.SELECTED : false}
                                                source={Object.values(yacht.details).every(v => v) ? require('../svg/'+theme+'/checkbox_active.svg') : require('../svg/checkbox.svg')}
                                                resizeMode="contain"
                                                style={tw`h-7.5 w-7.5 mr-2`}
                                            />
                                            <Span>{labels.current?.ALL}</Span>
                                        </Pressable>
                                        { Object.values(yacht.details).every(v => v) ? null : (
                                            Object.entries(yacht.details).map(([detailName, isSelected]) => (
                                                <Pressable
                                                    key={detailName}
                                                    style={tw`ml-6 flex-row justify-start py-1.5 items-center`}
                                                    onPress={() => toggleDetailSelection(yacht.id, detailName)}
                                                >
                                                    <Image
                                                        accessibilityLabel={isSelected ? labels.current?.SELECTED : false}
                                                        source={isSelected ? require('../svg/'+theme+'/checkbox_active.svg') : require('../svg/checkbox.svg')}
                                                        resizeMode="contain"
                                                        style={tw`h-7.5 w-7.5 mr-2`}
                                                    />
                                                    <Span>{'Shares' === detailName ? labels.current?.CREW_MEMBERS : detailName}</Span>
                                                </Pressable>
                                            ))
                                        )}
                                    </View>
                                ))}
                            </ScrollView>
                            <View style={tw`flex-row mt-5`}>
                                <Button
                                    title={ labels.current?.BACK }
                                    style={tw`w-full shrink py-2 bg-white border border-[${themes[theme].brand_primary}]`}
                                    styleHover={tw`bg-white`}
                                    styleText={tw`text-[${themes[theme].brand_primary}]`}
                                    styleHoverText={tw`text-[${themes[theme].brand_primary}]`}
                                    onPress={()=>{setExportSelectDetails(0)}}
                                />
                                <Button
                                    disabled={ exportYachts.some(yacht => Object.values(yacht.details).some(isSelected => isSelected)) > 0 ? false : true }
                                    title={ labels.current?.EXPORT }
                                    image={ exportYachts.some(yacht => Object.values(yacht.details).some(isSelected => isSelected)) ? { uri: require('../svg/export_white.svg') } : { uri: require('../svg/export_lightgray.svg') } }
                                    style={[tw`ml-2 w-full shrink py-2 bg-[${themes[theme].brand_primary}] border border-gray3`, exportYachts.some(yacht => Object.values(yacht.details).some(isSelected => isSelected)) > 0 && tw`border-[${themes[theme].brand_primary}]`]}
                                    styleHover={tw`bg-[${themes[theme].brand_primary}]`}
                                    styleText={tw`text-white`}
                                    styleHoverText={tw`text-white`}
                                    styleLoadingImage={tw`h-5`}
                                    validateGroup={newExportValidation}
                                    onPress={()=>{

                                        if ( newExportValidation ) { return; }

                                        doNewExportValidation(true);

                                        setTimeout(()=>
                                        {
                                            let payload = {
                                                meta: JSON.stringify(exportYachts),
                                            };
                                            
                                            fetch(apiUrl, {
                                                method: 'POST',
                                                cache: 'no-cache',
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify({
                                                    endpoint: 'export/add', v: apiVersion,
                                                    device_id: localStorage.getItem('deviceID'),
                                                    client_key: localStorage.getItem('clientKey'),
                                                    payload: payload
                                                }),
                                            })
                                            .then((response) => {
                                                return response.text().then(function(text) {
                                                    const result = JSON.parse(text);
                                                    if ( result.error )
                                                    {
                                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                        toast.show(result.error, {type: 'danger', duration:10000});
                                                        setExportModalActive(false); doNewExportValidation(false);
                                                        setTimeout(()=>{setExportSelectDetails(0); setExportYachts([]);},500);
                                                    }
                                                    else
                                                    {
                                                        let exportKey = result.obj_export.token;
                                                        let ttl = result.obj_export.ttl;
                                                        let days = Math.floor(ttl / 86400);
                                                        daysRef.current = days;
                                                        exportLinkRef.current = 'https://app.yachtwave.com/export?key='+exportKey
                                                        // window.open('https://app.yachtwave.com/export?key='+exportKey, '_blank');
                                                        setTimeout(()=>{setExportSelectDetails(2); doNewExportValidation(false); setExportYachts([]);},500);
                                                    }
                                                });
                                            })
                                            .catch(error => {
                                                console.warn(error);
                                                setExportModalActive(false); doNewExportValidation(false);
                                                setTimeout(()=>{
                                                    setExportSelectDetails(0); setExportYachts([]);
                                                    if ( ! window.navigator.onLine ) { toast.show('Internet connection failed. Please try again.', {type: 'danger', duration:10000}); }
                                                    else { toast.show('An error ocurred. See console log.', {type: 'danger', duration:10000}); }
                                                },500);
                                            });
                                        }, 1000);
                                    }}
                                />
                            </View>
                        </View>
                    :
                        <View>
                            <Heading hlevel={2} styleHlevel={3}>{ labels.current?.ALERT_TITLES_SUCCESS }</Heading>
                            <Span>{ labels.current?.EXPORT_SUCCESS.replace('%days%', daysRef.current) }</Span>
                            <View style={tw`flex-row mt-5`}>
                                <Button
                                    title={ labels.current?.VIEW }
                                    style={[tw`ml-2 w-full shrink py-2 bg-[${themes[theme].brand_primary}] border border-[${themes[theme].brand_primary}]`]}
                                    styleHover={tw`bg-[${themes[theme].brand_primary}]`}
                                    styleText={tw`text-white`}
                                    styleHoverText={tw`text-white`}
                                    onPress={()=>{
                                        window.open(exportLinkRef.current, '_blank');
                                        setExportModalActive(false);
                                        setTimeout(()=>{setExportSelectDetails(0);},500);
                                    }}
                                />
                            </View>
                        </View>
                    ) }
                </Modal>
            }

            { true === enabledFeatures.import &&
                <Modal
                    style={[tw`p-0`, 2 === importSelectDetails && tw`w-112 max-w-[90%]`]}
                    active={importModalActive}
                    setActive={setImportModalActive}
                    doWhileExit={()=>{
                        // remove account.import from attempted_view_args
                        let attempted_view_args = JSON.parse(localStorage.getItem('attempted-view-args'));
                        delete attempted_view_args?.account?.import;
                        delete attempted_view_args?.account?.id;
                        localStorage.setItem('attempted-view-args', JSON.stringify(attempted_view_args));
                        setTimeout(()=>{if (newAttachmentInput?.current?.value) {newAttachmentInput.current.value = '';} if (attachmentFileNameInput?.current?.value) {attachmentFileNameInput.current.value = '';} setImportSelectDetails(0); setImportYachts([]); setAttachmentData(''); doNewImportValidation(false);},500);
                    }}
                >
                    { 1 === importSelectDetails && importYachts.length > 0 ?
                        <View style={tw`p-5`}>
                            <Heading hlevel={2} styleHlevel={3}>{ labels.current?.IMPORT_CHOOSE_DATA_TITLE }</Heading>
                            <Span>{ labels.current?.IMPORT_CHOOSE_DATA_BODY }</Span>
                            <ScrollView
                                style={{width: '100%', overflowX: 'visible', maxHeight: 228, marginTop: 16}}
                                contentContainerStyle={tw`w-full min-h-full justify-start flex-col bg-gray0 rounded-md p-4`}
                            >
                                {importYachts.map((yacht, index) => (
                                    yacht && yacht?.id &&
                                    <View key={yacht.id}>
                                        <Heading hlevel={3} styleHlevel={4} style={tw`mt-4 ${!index && 'mt-0'}`}>{yacht.name}</Heading>
                                        { Object.values(yacht.details).every(v => v) ? null : (
                                            Object.entries(yacht.details).map(([detailName, isSelected]) => (
                                                <Pressable
                                                    key={detailName}
                                                    style={tw`flex-row justify-start py-1.5 items-center`}
                                                    onPress={() => toggleImportDetailSelection(yacht.id, detailName)}
                                                >
                                                    <Image
                                                        accessibilityLabel={isSelected ? labels.current?.SELECTED : false}
                                                        source={isSelected ? require('../svg/'+theme+'/radioButton_active.svg') : require('../svg/radioButton.svg')}
                                                        resizeMode="contain"
                                                        style={tw`h-7.5 w-7.5 mr-2`}
                                                    />
                                                    <Span>{'Shares' === detailName ? labels.current?.CREW_MEMBERS : detailName}</Span>
                                                </Pressable>
                                            ))
                                        )}
                                    </View>
                                ))}
                            </ScrollView>
                            <View style={tw`flex-row mt-5`}>
                                <Button
                                    title={ labels.current?.BACK }
                                    style={tw`w-full shrink py-2 bg-white border border-[${themes[theme].brand_primary}]`}
                                    styleHover={tw`bg-white`}
                                    styleText={tw`text-[${themes[theme].brand_primary}]`}
                                    styleHoverText={tw`text-[${themes[theme].brand_primary}]`}
                                    onPress={()=>{setImportSelectDetails(0)}}
                                />
                                <Button
                                    disabled={ importYachts[0]?.details && Object.entries(importYachts[0].details).map(([detailName, isSelected]) => {
                                            if ( isSelected ) { return detailName; }
                                        }).filter(v => v).length > 0 ? false : true }
                                    title={ labels.current?.NEXT }
                                    // image={ deleteMatch === deleteConfirm ? { uri: require('../svg/delete_white.svg') } : { uri: require('../svg/delete_lightgray.svg') } }
                                    style={[tw`ml-2 w-full shrink py-2 bg-[${themes[theme].brand_primary}] border border-gray3`, importYachts[0]?.details && Object.entries(importYachts[0].details).map(([detailName, isSelected]) => {
                                            if ( isSelected ) { return detailName; }
                                        }).filter(v => v).length > 0 && tw`border-[${themes[theme].brand_primary}]`]}
                                    styleHover={tw`bg-[${themes[theme].brand_primary}]`}
                                    styleText={tw`text-white`}
                                    styleHoverText={tw`text-white`}
                                    onPress={()=>{setImportSelectDetails(2)}}
                                />
                            </View>
                        </View>

                    : ( 2 === importSelectDetails ?
                        <View>
                            <View style={tw`bg-gray0 border-b-2 border-gray1 items-center h-20 px-5 pt-7 pb-6 flex-row rounded-t-md`}>
                                <Pressable
                                    style={tw`p-2 -ml-2 -mt-2`}
                                    onPress={() => {if (newAttachmentInput?.current?.value) {newAttachmentInput.current.value = '';} if (attachmentFileNameInput?.current?.value) {attachmentFileNameInput.current.value = '';} setImportSelectDetails(1)}}
                                >
                                    <Image
                                        accessibilityLabel={ labels.current?.CANCEL }
                                        source={{ uri: require('../svg/'+theme+'/back.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-6 w-6 top-1 mr-2`}
                                    />
                                </Pressable>
                                <Heading style={tw`mt-0 leading-4 mb-0 text-ink`} hlevel={2} styleHlevel={2}>{ labels.current?.IMPORT_CSV }</Heading>
                            </View>
                            <View style={tw`pt-2 px-6 pb-6`}>
                                <Heading hlevel={3} styleHlevel={3}>
                                    {
                                        Object.entries(importYachts[0].details).map(([detailName, isSelected]) => {
                                            if ( isSelected ) { return detailName; }
                                        }).filter(v => v)[0]
                                    }
                                    { ' ' + labels.current?.IMPORT }
                                </Heading>
                                <Span>{ labels.current?.IMPORT_DOWNLOAD_TEMPLATE_BODY }</Span>

                                <View style={tw`flex-initial`}>
                                    <Button
                                        title={ labels.current?.IMPORT_DOWNLOAD_TEMPLATE }
                                        style={tw`py-3 my-4 w-max inline-block shrink`}
                                        onPress={()=>{ generateCSVandDownload(importYachts) }}
                                    />
                                </View>

                                <Pressable
                                    style={tw`mt-1 mb-3 w-full`}
                                    onPress={()=>{newAttachmentInput.current.click();}}
                                >
                                    <TextInput
                                        style={tw`pointer-events-none`}
                                        placeholder={ labels.current?.SELECT +' '+ labels.current?.ATTACHMENT_FILE +'*' }
                                        image={ attachmentData ? { uri: require('../svg/'+theme+'/upload.svg') } : { uri: require('../svg/upload_gray.svg') }}
                                        // defaultValue={attachmentType}
                                        passedRef={attachmentFileNameInput}
                                        required
                                    />

                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                    />
                                </Pressable>

                                <Button
                                    disabled={ ! attachmentData ? true : false }
                                    title={ labels.current?.IMPORT }
                                    image={ ! attachmentData ? { uri: require('../svg/import_lightgray.svg') } : { uri: require('../svg/import_white.svg') } }
                                    validateGroup={newImportValidation}
                                    style={tw`mt-2`}
                                    onPress={()=>{
                                        if ( ! attachmentData || newImportValidation ) { return; }

                                        doNewImportValidation(true);

                                        let payloads = prepareImportData( readString(attachmentData)?.data, Object.entries(importYachts[0].details).map(([detailName, isSelected]) => {
                                                if ( isSelected ) { return detailName; }
                                            }).filter(v => v)[0], importYachts[0].id);

                                        importData(
                                            payloads,
                                            importResourceTypeMap[Object.entries(importYachts[0].details).map(([detailName, isSelected]) => {
                                                    if ( isSelected ) { return detailName; }
                                                }).filter(v => v)[0]],
                                            toast,
                                            labels.current?.IMPORT +' '+ labels.current?.COMPLETED,
                                            GLOBALS,
                                            function(result)
                                            {
                                                if ( 'success' === result ) {
                                                    setLastUpdate(now());
                                                    /*setTimeout(()=>{importSuccessRedirectMap[Object.entries(importYachts[0].details).map(([detailName, isSelected]) => {
                                                        if ( isSelected ) { return detailName; }
                                                    }).filter(v => v)[0]](importYachts[0].id);}, 1000);*/
                                                    setTimeout(()=>{
                                                        if (newAttachmentInput?.current?.value) {newAttachmentInput.current.value = '';} if (attachmentFileNameInput?.current?.value) {attachmentFileNameInput.current.value = '';}
                                                        toggleImportDetailSelection(importYachts[0].id, '');
                                                        setAttachmentData('');
                                                        doNewImportValidation(false);
                                                        setImportSelectDetails(1);
                                                    }, 1250);
                                                }
                                                else {
                                                    setLastUpdate(now());
                                                    // empty value from newAttachmentInput
                                                    if (newAttachmentInput?.current?.value) {newAttachmentInput.current.value = '';} if (attachmentFileNameInput?.current?.value) {attachmentFileNameInput.current.value = '';}
                                                    setAttachmentData('');
                                                    doNewImportValidation(false);
                                                }
                                            }
                                        );
                                    }}
                                />

                                <AttachmentUploadInput
                                    passedRef={newAttachmentInput}
                                    onChange={(e)=>{
                                        if ( ! e.target.files[0] || newImportValidation ) { return; }
                                        // if size is > 30 MB
                                        if ( e.target.files[0].size > ( settings?.max_size_document ? Math.round(parseInt(settings.max_size_document, 10)) : 31457280 ) ) { toast.show(labels.current?.ATTACHMENT_TOO_LARGE_BODY.replace('%size%', '30 MB'), {type: 'danger', duration:10000}); return; }

                                        attachmentFileNameInput.current.value = e.target.files[0].name;
                                        if ( 'text/csv' !== e.target.files[0].type ) { toast.show(labels.current?.ATTACHMENT_OPEN_ERROR, {type: 'danger', duration:10000}); return; }

                                        processAttachmentInput(e.target.files[0]).then((result)=>{
                                            // convert results from base64 to plain text
                                            setAttachmentData(atob(result));
                                        }).catch((error)=>{
                                            toast.show(error, {type: 'danger', duration:10000});
                                        });
                                    }}
                                />
                            </View>
                        </View>
                    :
                        <View style={tw`p-5`}>
                            <Heading hlevel={2} styleHlevel={3}>{ labels.current?.IMPORT_CHOOSE_VESSEL_TITLE }</Heading>
                            <Span>{ labels.current?.IMPORT_CHOOSE_VESSEL_BODY }</Span>
                            <ScrollView
                                style={{width: '100%', overflowX: 'visible', maxHeight: 228, marginTop: 16}}
                                contentContainerStyle={tw`w-full min-h-full justify-start flex-col bg-gray0 rounded-md p-4`}
                            >
                                { importYachtsAvailable.map((yacht, i)=>{
                                    return <Pressable key={i} style={tw`flex-row justify-start py-1.5 items-center`}
                                        onPress={()=>{ toggleSelectedImportYacht(yacht.id) }}
                                    >
                                        <Image
                                            accessibilityLabel={ yacht && yacht?.id && importYachts.find((y)=>{ return y && y?.id && yacht && yacht?.id && y.id === yacht.id }) ? labels.current?.SELECTED : false }
                                            source={ importYachts.find((y)=>{ return y && y?.id && yacht && yacht?.id && y.id === yacht.id }) ? {uri: require('../svg/'+theme+'/radioButton_active.svg')} : {uri: require('../svg/radioButton.svg')} }
                                            resizeMode="contain"
                                            style={tw`h-7.5 w-7.5 mr-2`}
                                        />
                                        <Span>{yacht.name}</Span>
                                    </Pressable>
                                })}
                            </ScrollView>
                            <View style={tw`flex-row mt-5`}>
                                <Button
                                    title={ labels.current?.CANCEL }
                                    style={tw`w-full shrink py-2 bg-white border border-[${themes[theme].brand_primary}]`}
                                    styleHover={tw`bg-white`}
                                    styleText={tw`text-[${themes[theme].brand_primary}]`}
                                    styleHoverText={tw`text-[${themes[theme].brand_primary}]`}
                                    onPress={()=>{
                                        setImportModalActive(false);
                                        // remove account.import from attempted_view_args
                                        let attempted_view_args = JSON.parse(localStorage.getItem('attempted-view-args'));
                                        delete attempted_view_args?.account?.import;
                                        localStorage.setItem('attempted-view-args', JSON.stringify(attempted_view_args));
                                        setTimeout(()=>{setImportSelectDetails(0); setImportYachts([]);},500);
                                    }}
                                />
                                <Button
                                    disabled={ importYachts.length > 0 ? false : true }
                                    title={ labels.current?.NEXT }
                                    // image={ deleteMatch === deleteConfirm ? { uri: require('../svg/delete_white.svg') } : { uri: require('../svg/delete_lightgray.svg') } }
                                    style={[tw`ml-2 w-full shrink py-2 bg-[${themes[theme].brand_primary}] border border-gray3`, importYachts.length > 0 && tw`border-[${themes[theme].brand_primary}]`]}
                                    styleHover={tw`bg-[${themes[theme].brand_primary}]`}
                                    styleText={tw`text-white`}
                                    styleHoverText={tw`text-white`}
                                    onPress={()=>{setImportSelectDetails(1)}}
                                />
                            </View>
                        </View>
                    ) }
                </Modal>
            }

            <Modal
                active={showChangeLog}
                setActive={setShowChangeLog}
                style={tw`w-[640px] max-w-[90%]`}
            >
                <Heading hlevel={2} styleHlevel={2} style={tw`mb-1`}>What's new?</Heading>
                <HorizontalRule style={tw`mt-5`}></HorizontalRule>

                <ScrollView
                    style={{marginTop:'-1px',width:'100%',maxHeight: tw.prefixMatch('lg') ? 'calc('+height+'px - 348px)' : 'calc('+height+'px - 192px)',overflowX:tw.prefixMatch('lg') ? 'visible' : 'hidden'}}
                    contentContainerStyle={tw`w-full justify-start px-0`}
                >
                    { changelogRef.current?.versions?.map((log, i)=>{
                        return <View key={i} style={tw`mb-5`}>
                            <HorizontalRule style={tw`mb-5`}></HorizontalRule>
                            <Heading hlevel={3} styleHlevel={2} style={tw`mt-2 mb-6`}>{ log.version } | { log.date }</Heading>
                            { log?.changes?.map((change, i2)=>{
                                return <View key={i2} style={tw`mb-5`}>
                                    <Span style={tw`font-bold`}>{change.title}</Span>
                                    <Span>{change.desc}</Span>
                                </View>
                            }) }
                        </View>
                    }) }
                </ScrollView>
            </Modal>


        </View>
    )
}

export default AccountView;
