import React from 'react';
import { Image, View, ScrollView, Pressable } from 'react-native';
import { create, useDeviceContext } from 'twrnc';
import { Button, Link, Span, TextDivider, TextInput, SelectMenu, HorizontalRule, Notification } from '../elements';
import { useSpring, animated, config } from 'react-spring';
import { getLabel, getPropertiesByGroup, getOptionsByLabelGroup, makeCopyright, debounce } from '../helpers';
import { GlobalContext } from '../global';

function WelcomeView ({route, navigation})
{
    const tw = create( require(`../../tailwind.config.js`) );
    useDeviceContext(tw);

    const AnimatedView = animated(View);
    let {
        version,
        apiUrl,
        apiVersion,
        recaptchaKey,
        userFirstName, setUserFirstName,
        userLastName, setUserLastName,
        userEmail, setUserEmail,
        userPhone, setUserPhone,
        userPhonePrefix, setUserPhonePrefix,
        enabledFeatures,
        themes, theme
    } = React.useContext(GlobalContext);

    const copyright = makeCopyright(version);

    const [isReveal, setIsReveal] = React.useState(true);
    const [isLoggingIn, setIsLoggingIn] = React.useState( 'undefined' != typeof route.params && 'undefined' != typeof route.params.createAccount ? false : true );
    const [isRegistering, setIsRegistering] = React.useState( 'undefined' != typeof route.params && 'undefined' != typeof route.params.createAccount ? true : false);
    
    const [userRegistrationEmail, setUserRegistrationEmail] = React.useState(userEmail);
    const [userRegistrationCountry, setUserRegistrationCountry] = React.useState('1');
    const [userRegistrationCountryVisible, setUserRegistrationCountryVisible] = React.useState(false);
    // const [userPhoneMasked, setUserPhoneMasked] = React.useState('');

    const [loginValidation, doLoginValidation] = React.useState(false);
    const [invalidLogin, invalidateLogin] = React.useState(false);
    const [invalidLoginMsg, setInvalidLoginMsg] = React.useState('Invalid email address');
    const [registrationValidation, doRegistrationValidation] = React.useState(false);
    const [invalidRegistrationMsg, setInvalidRegistrationMsg] = React.useState('Please review the issues below');
    const [invalidRegistration, invalidateRegistration] = React.useState(false);
    const [registrationMsg, setRegistrationMsg] = React.useState('');
    const [invalidEmail, invalidateEmail] = React.useState(false);

    let countryOptions = getOptionsByLabelGroup('data.countries', true, 'id');
    let countryFlags = getPropertiesByGroup( 'value', 'data.countries', 'img_flag', false, true );
    let countryCodes = getPropertiesByGroup( 'value', 'data.countries', 'code', false, true );

    const loginEmailInput = React.useRef(null);

    countryOptions.forEach((value, index)=>
    {
        let foundFlag = countryFlags.find(obj => {return parseInt(obj.value) === parseInt(value.value)});
        let foundCode = countryCodes.find(obj => {return parseInt(obj.value) === parseInt(value.value)});
        value.badge = foundFlag ? { uri: require(`../images/flags/iso2/${foundFlag.img_flag}`) } : { uri: require('../svg/BlankFlag.svg') }
        value.code = foundCode.code;
        value.sublabel = '+'+foundCode.code;
    });

    const debouncedOnChangeLoginEmail = React.useCallback(
        debounce((newText) => {
            setUserEmail(newText);
        }, 300), // 300ms delay
        [] // dependencies (none in this case, so the callback is memoized after the first render)
    );

    let logoAnimation = useSpring(
    {
        from: { 
            willChange: 'transform',
            x: tw.prefixMatch('md') ? '50%' : '0%',
            y: ! tw.prefixMatch('md') ? ( tw.prefixMatch('landscape') ? '170%' : '230%' ) : '0%',
        },
        to: { 
            x: '0%',
            y: ! tw.prefixMatch('md') ? ( tw.prefixMatch('landscape') ? '171%' : ( isLoggingIn ? '100%' : ( ! isRegistering ? '120%' : '0%' ) ) ) : '0%',
        },
        // delay: isReveal ? 750 : ( isLoggingIn ? 250 : ( isRegistering ? 100 : 0 ) ),
        delay: isReveal ? 100 : 0,
        config: tw.prefixMatch('md')
            ? { mass: 1, tension: 190, friction: 30 }
            : config.default,
        onRest: () => { setIsReveal(false); }
    });

    let loginAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: ! isLoggingIn || isRegistering ? 1 : 2,
            position: 'absolute',
            width: '100%',
            height: tw.prefixMatch('md')
                ? '100%'
                : ( tw.prefixMatch(`sm`) && tw.prefixMatch(`landscape`) 
                    ? '100%'
                    : '342px'
                   ),
            
            transform: tw.prefixMatch('md')
                ? 'translateX(110%)'
                : 'translateY(110%)',
        },
        to: {    
            zIndex: ! isLoggingIn || isRegistering ? 1 : 2,   
            height: tw.prefixMatch('md')
                ? '100%'
                : ( tw.prefixMatch(`sm`) && tw.prefixMatch(`landscape`) 
                    ? '100%'
                    : '342px'
                   ),      
            transform: tw.prefixMatch('md')
                ? ( ! isLoggingIn 
                    ? 'translateX(110%)'
                    : ( isRegistering 
                        ? 'translateX(110%)'
                        : 'translateX(0%)' 
                      ) 
                ) 
                : ( ! isLoggingIn 
                    ? 'translateY(110%)'
                    : ( isRegistering 
                        ? 'translateY(110%)'
                        : 'translateY(0%)' 
                      ) 
                )
        },
        delay: isReveal ? 100 : 0,
        config: tw.prefixMatch('md')
            ? { mass: 1, tension: 190, friction: 30 } 
            : config.default,
    });

    let registrationAnimation = useSpring(
    {
        from: { 
            willChange: 'transform',
            zIndex: ! isRegistering || isLoggingIn ? 1 : 2,
            position: 'absolute',
            width: '100%',
            height: tw.prefixMatch('md')
                ? '100%'
                : ( tw.prefixMatch(`sm`) && tw.prefixMatch(`landscape`) 
                    ? '100%'
                    : '538px'
                   ),
            transform: tw.prefixMatch('md')
                ? 'translateX(110%)'
                : 'translateY(110%)',
        },
        to: {  
            zIndex: ! isRegistering || isLoggingIn ? 1 : 2,  
            height: tw.prefixMatch('md')
                ? '100%'
                : ( tw.prefixMatch(`sm`) && tw.prefixMatch(`landscape`) 
                    ? '100%'
                    : '538px'
                   ),         
            transform: tw.prefixMatch('md')
                ? ( ! isRegistering
                    ? 'translateX(110%)'
                    : ( isLoggingIn  
                        ? 'translateX(110%)'
                        : 'translateX(0%)' 
                      ) 
                ) 
                : ( ! isRegistering
                    ? 'translateY(110%)'
                    : (  isLoggingIn 
                        ? 'translateY(110%)'
                        : 'translateY(0%)' 
                      ) 
                )
        },
        config: tw.prefixMatch('md')
            ? { mass: 1, tension: 190, friction: 30 } 
            : config.default,
    });

    return (
    <View label="welcome" style={tw`bg-[${themes[theme].brand_primary}] h-screen overflow-hidden`}>
        <View style={tw`bg-[${themes[theme].brand_primary}] h-full md:flex-row items-center justify-between md:justify-start`}>

            <View style={tw`w-full md:w-1/2 h-full justify-center shrink`}>
                <AnimatedView style={logoAnimation}>

                    <Image
                        accessibilityLabel="YachtWave"
                        source={{ uri: require('../svg/'+theme+'/LogoWhite.svg') }}
                        resizeMode="contain"
                        style={tw`h-28 md:h-40 mt-1 md:mt-0`}
                    />

                </AnimatedView>
            </View>

            <View style={tw`w-full md:w-1/2 h-[538px] sm:landscape:h-full md:h-full relative`} >
                <AnimatedView style={ loginAnimation }>
                    <View style={tw`mt-[201px] md:mt-0 sm:landscape:mt-0 bg-[${themes[theme].brand_primary}] select-none h-full justify-center items-center px-6 md:p-16 rounded-t-3xl md:rounded-l-3xl md:rounded-tr-0 shadow-black shadow-opacity-25 shadow-radius-8 overflow-hidden`}>

                        <ScrollView
                            centerContent={true}
                            style={{width:'100%'}}
                            contentContainerStyle={tw`w-full items-center`}
                        >

                            <TextDivider style={tw`mt-8 lg:w-80`} hlevel={2} >
                                { getLabel('LOGIN_TITLE') }
                            </TextDivider>

                            { invalidLogin && 
                                <Notification 
                                    style={tw`my-4 lg:w-80`}
                                    type="warning"
                                >{invalidLoginMsg}</Notification>
                            }
    
                            <TextInput
                                testID="login-email"
                                style={tw`bg-black/20 my-1 md:my-4 w-full lg:w-80 border-transparent`}
                                styleInput={tw`text-white`}
                                styleFocus={tw`border-white`}
                                styleDisabledInput={tw`text-white`}
                                styleDisabled={tw`border-transparent grayscale-0`}
                                autoCapitalize="off"
                                autoComplete="username"
                                autoCorrect={ false }
                                placeholder={ getLabel('CREATE_HINT_EMAIL') }
                                // defaultValue={userEmail}
                                placeholderTextColor="#FFFFFFB3"
                                keyboardType="email-address"
                                // pattern="^\S+@\S+\.\S+$"
                                validateGroup={loginValidation}
                                onChangeText={debouncedOnChangeLoginEmail}
                                disabled={ isRegistering }
                                passedRef={loginEmailInput}
                                required
                            />
        
                            <Button
                                title={ getLabel('LOGIN_BUTTON') }
                                style={tw`bg-white my-4 w-full lg:w-80 border border-transparent`}
                                styleText={tw`text-[${themes[theme].brand_primary}]`}
                                styleHover={tw`bg-[${themes[theme].brand_primary}] border-white`}
                                styleHoverText={tw`text-white`}
                                styleLoading={tw`bg-[${themes[theme].brand_primary}] border-white`}
                                validateGroup={loginValidation}
                                disabled={ isRegistering }
                                onPress={() => {
                                    if ( loginValidation ) { return; }
                                    doLoginValidation(true);
                                    setTimeout(() => 
                                    {
                                        if ( loginEmailInput.current.value )
                                        {
                                            window.grecaptcha.ready(function() {
                                                window.grecaptcha.execute(recaptchaKey, {action: 'login'}).then(function(token) {

                                                    fetch(apiUrl, {
                                                        method: 'POST',
                                                        cache: 'no-cache',
                                                        headers: {
                                                            'Content-Type': 'application/json'
                                                        },
                                                        body: JSON.stringify({
                                                            endpoint: 'user/login', v: apiVersion,
                                                            device_id: localStorage.getItem('deviceID'),
                                                            payload: {
                                                                email: loginEmailInput.current.value,
                                                                captcha: token,
                                                            }
                                                        }),
                                                    })
                                                    .then((response) => {
                                                        return response.text().then(function(text) {
                                                            const result = JSON.parse(text);
                                                            if ( result.error )
                                                            {
                                                                /*
                                                                if result.error includes substring "[400]"
                                                                we remove "[400]" from result.error and setInvalidRegistrationMsg(result.error)
                                                                then we take the setUserRegistrationEmail(loginEmailInput.current.value)
                                                                then switch to the registration form
                                                                 */

                                                                if ( result.error.includes('[400]') )
                                                                {
                                                                    setRegistrationMsg(getLabel('CREATE_INSTRUCTIONS'));
                                                                    setUserRegistrationEmail(loginEmailInput.current.value);
                                                                    setIsLoggingIn(false);
                                                                    setIsRegistering(true);
                                                                    invalidateLogin(false);
                                                                    setTimeout(()=>{doLoginValidation(false);}, 2000);
                                                                    invalidateRegistration(false);
                                                                    invalidateEmail(true);
                                                                    doRegistrationValidation(false);
                                                                }
                                                                else {
                                                                    setTimeout(()=>{
                                                                        setInvalidLoginMsg(result.error);
                                                                        invalidateLogin(true);
                                                                        doLoginValidation(false);
                                                                    }, 2000);
                                                                }
                                                            }
                                                            else
                                                            {
                                                                navigation.navigate('login', {
                                                                    verbiage: result.msg,
                                                                    sid_mobile: result.sid_mobile,
                                                                    mobile_masked: result.mobile_masked
                                                                });

                                                                // setIsLoggingIn(false);
                                                                // setIsRegistering(false);
                                                                invalidateLogin(false);
                                                                setTimeout(()=>{doLoginValidation(false);}, 2000);
                                                            }
                                                        });
                                                    })
                                                    .catch(error => {
                                                        console.warn(error);
                                                        setInvalidLoginMsg('An Error Occurred');
                                                        invalidateLogin(true);
                                                        setTimeout(()=>{doLoginValidation(false);}, 2000);
                                                    });

                                                });
                                            });
                                        }
                                        else 
                                        {
                                            setTimeout(()=>{
                                                setInvalidLoginMsg('Invalid email address');
                                                invalidateLogin(true);
                                                doLoginValidation(false);
                                            }, 2000);
                                        }
                                    }, 250);
                                }}
                            />

                        </ScrollView>
    
                        <View style={tw`md:absolute bottom-5 md:bottom-8 w-full`}>

                            <HorizontalRule style={tw`bg-black/20 w-screen my-5 md:my-8`} />

                            <Span style={tw`text-sm px-1 md:text-base text-center text-white`}>
                                { getLabel('LOGIN_CREATE_FOOTER_TEXT') }{ tw.prefixMatch('md') && ! tw.prefixMatch('lg') ? "\r\n" : " "}

                                <Link 
                                    style={tw`text-white`}
                                    onPress={()=>{setIsRegistering(true);setIsLoggingIn(false)}} 
                                    accessibilityRole={ isRegistering ? false : "button" }
                                >{ getLabel('LOGIN_CREATE_FOOTER_LINK') }</Link>
                            </Span>

                            <Span style={tw`md:hidden w-full text-center text-white/50 text-xs pointer-events-none mt-3`}>{copyright}</Span>

                        </View>   
                    </View>
                </AnimatedView>
    
                <AnimatedView style={ registrationAnimation }>
                    <View style={tw`mt-0 bg-[${themes[theme].brand_primary}] select-none h-full justify-center items-center px-6 md:p-16 rounded-t-3xl md:rounded-l-3xl md:rounded-tr-0 shadow-black shadow-opacity-25 shadow-radius-8 overflow-hidden`}>
                        <ScrollView
                            centerContent={true}
                            style={{width:'100%'}}
                            contentContainerStyle={tw`w-full items-center pt-6 sm:landscape:pt-12 md:pt-0`}
                        >
                            <TextDivider style={tw`mt-0 mb-6 md:mt-8 lg:w-80`} hlevel={2} >
                                { getLabel('CREATE_TITLE') }
                            </TextDivider>

                            { invalidRegistration && 
                                <Notification 
                                    style={tw`my-4 lg:w-80`}
                                    type="warning"
                                >{invalidRegistrationMsg}</Notification>
                            }

                            { invalidEmail &&
                                <Span style={tw`text-white text-sm mb-4 lg:w-80`}>{registrationMsg}</Span>
                            }

                            { false === enabledFeatures.registration &&

                                <View>
                                    <Notification
                                        style={tw`m-4 w-80`}
                                        type="info"
                                    >Registration is temporarily only available through the mobile app.
                                    </Notification>

                                    <View style={tw`flex-row`}>
                                        <Link style={tw`flex w-full`} href="https://apps.apple.com/us/app/yachtwave/id6447794172">
                                            <Image
                                                accessibilityLabel="YachtWave iOS App"
                                                source={{ uri: require('../svg/available-on-official-black-ios.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-12 mt-1 w-44`}
                                            />
                                        </Link>
                                        <Link style={tw`flex w-full`} href="https://play.google.com/store/apps/details?id=com.yachtwave.app">
                                            <Image
                                                accessibilityLabel="YachtWave Android App"
                                                source={{ uri: require('../svg/available-on-official-black-android.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-12 mt-1 w-44`}
                                            />
                                        </Link>
                                    </View>
                                </View>
                            }
    
                            { true === enabledFeatures.registration &&

                                <View>
                                    <TextInput
                                        testID="register-first-name"
                                        style={tw`bg-black/20 my-2 w-full lg:w-80 border-transparent`}
                                        styleInput={tw`text-white`}
                                        styleFocus={tw`border-white`}
                                        styleDisabledInput={tw`text-white`}
                                        styleDisabled={tw`border-transparent grayscale-0`}
                                        styleInvalid={tw`border-red`}
                                        placeholder={ getLabel('CREATE_HINT_FIRSTNAME') }
                                        autoComplete="first name"
                                        // defaultValue={userFirstName}
                                        placeholderTextColor="#FFFFFFB3"
                                        pattern="^.{2,}$"
                                        validateGroup={registrationValidation}
                                        validatedValue={(value) => {setUserFirstName(value)}}
                                        disabled={ isLoggingIn }
                                        required
                                    />

                                    <TextInput
                                        testID="register-last-name"
                                        style={tw`bg-black/20 my-2 w-full lg:w-80 border-transparent`}
                                        styleInput={tw`text-white`}
                                        styleFocus={tw`border-white`}
                                        styleDisabledInput={tw`text-white`}
                                        styleDisabled={tw`border-transparent grayscale-0`}
                                        styleInvalid={tw`border-red`}
                                        placeholder={ getLabel('CREATE_HINT_LASTNAME') }
                                        autoComplete="last name"
                                        // defaultValue={userLastName}
                                        placeholderTextColor="#FFFFFFB3"
                                        pattern="^.{2,}$"
                                        validateGroup={registrationValidation}
                                        validatedValue={(value) => {setUserLastName(value)}}
                                        disabled={ isLoggingIn }
                                        required
                                    />

                                    <TextInput
                                        testID="register-email"
                                        style={tw`bg-black/20 my-2 w-full lg:w-80 border-transparent`}
                                        styleInput={tw`text-white`}
                                        styleFocus={tw`border-white`}
                                        styleDisabledInput={tw`text-white`}
                                        styleDisabled={tw`border-transparent grayscale-0`}
                                        styleInvalid={tw`border-red`}
                                        autoCapitalize="off"
                                        autoComplete="email"
                                        placeholder={ getLabel('CREATE_HINT_EMAIL') }
                                        defaultValue={userRegistrationEmail || ''}
                                        placeholderTextColor='#FFFFFFB3'
                                        keyboardType="email-address"
                                        pattern="^\S+@\S+\.\S+$"
                                        validateGroup={registrationValidation}
                                        validatedValue={(value) => {setUserRegistrationEmail(value)}}
                                        disabled={ isLoggingIn }
                                        required
                                    />

                                    <View style={tw`flex flex-row w-full lg:w-80`}>

                                        <Pressable
                                            style={tw`bg-black/20 rounded-[4px] my-2 mr-4 shrink`}
                                            onPress={()=>{setUserRegistrationCountryVisible(true)}}
                                            disabled={ isLoggingIn }
                                        >
                                            <TextInput
                                                style={tw`bg-transparent w-10/12 border-transparent pointer-events-none`}
                                                styleInput={tw`text-white w-full`}
                                                styleFocus={tw`border-white`}
                                                styleDisabledInput={tw`text-white`}
                                                placeholderTextColor='#FFFFFFB3'
                                                styleDisabled={tw`border-transparent grayscale-0`}
                                                styleInvalid={tw`border-red`}
                                                value={'+' + countryOptions.find(obj => {return parseInt(obj.value) === parseInt(userRegistrationCountry)})?.code}
                                                showSoftInputOnFocus={true}
                                            />

                                            <Image
                                                accessibilityHidden={true}
                                                source={{ uri: require('../svg/chevron_down_white.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                            />
                                        </Pressable>

                                        <TextInput
                                            testID="register-phone"
                                            style={tw`bg-black/20 my-2 w-8/12 border-transparent grow`}
                                            styleInput={tw`text-white w-full`}
                                            styleFocus={tw`border-white`}
                                            styleDisabledInput={tw`text-white`}
                                            styleDisabled={tw`border-transparent grayscale-0`}
                                            styleInvalid={tw`border-red`}
                                            placeholder={ getLabel('CREATE_HINT_MOBILE') }
                                            autoComplete="mobile phone"
                                            // defaultValue={userPhone}
                                            placeholderTextColor='#FFFFFFB3'
                                            keyboardType="phone-pad"
                                            // mask='(999) 999-9999'
                                            // value={userPhoneMasked}
                                            // onChangeText={(value)=>{setUserPhoneMasked(value)}}
                                            // pattern="^\(*[2-9]\d{2}\)*[-. /]*\d{3}[-. /]*\d{4} *e*x*t*\.* *\d{0,4}$"
                                            validateGroup={registrationValidation}
                                            validatedValue={(value) => {setUserPhone(value)}}
                                            disabled={ isLoggingIn }
                                            required
                                        />

                                    </View>

                                    <Button
                                        title={ getLabel('CREATE_BUTTON') }
                                        style={tw`bg-white my-2 w-full lg:w-80 border border-transparent`}
                                        styleText={tw`text-[${themes[theme].brand_primary}]`}
                                        styleHover={tw`bg-[${themes[theme].brand_primary}] border-white`}
                                        styleHoverText={tw`text-white`}
                                        // loadOnPress={true}
                                        // loadingImage={require('../svg/loader_blue.svg')}
                                        styleLoading={tw`bg-[${themes[theme].brand_primary}] border-white`}
                                        validateGroup={registrationValidation}
                                        disabled={ isLoggingIn }
                                        onPress={() => {
                                            if ( registrationValidation ) { return; }

                                            doRegistrationValidation(true);
                                            setTimeout(() =>
                                            {
                                                if ( userFirstName
                                                   && userLastName
                                                   && userRegistrationEmail
                                                   && userPhonePrefix
                                                   && userPhone )
                                                {
                                                    window.grecaptcha.ready(function() {
                                                        window.grecaptcha.execute(recaptchaKey, {action: 'registration'}).then(function(token)
                                                        {
                                                            fetch(apiUrl, {
                                                                method: 'POST',
                                                                cache: 'no-cache',
                                                                headers: {
                                                                    'Content-Type': 'application/json'
                                                                },
                                                                body: JSON.stringify({
                                                                    endpoint: 'user/add', v: apiVersion,
                                                                    device_id: localStorage.getItem('deviceID'),
                                                                    payload: {
                                                                        email: userRegistrationEmail,
                                                                        country_id: userRegistrationCountry,
                                                                        country_code: userPhonePrefix,
                                                                        mobile: userPhone,
                                                                        first_name: userFirstName,
                                                                        last_name: userLastName,
                                                                        captcha: token,
                                                                    }
                                                                }),
                                                            })
                                                            .then((response) => {
                                                                return response.text().then(function(text) {
                                                                    const result = JSON.parse(text);
                                                                    if ( result.error )
                                                                    {
                                                                        setTimeout(()=>{
                                                                            setInvalidRegistrationMsg(result.error);
                                                                            invalidateRegistration(true);
                                                                            doRegistrationValidation(false);
                                                                        }, 2000);
                                                                    }
                                                                    else
                                                                    {
                                                                        setUserEmail(userRegistrationEmail);
                                                                        navigation.navigate('verify', {
                                                                            sid_mobile: result.sid_mobile,
                                                                            mobile_masked: result.mobile_masked,
                                                                            sid_email: result.sid_email,
                                                                        });

                                                                        setTimeout(()=>{
                                                                            // setIsLoggingIn(false);
                                                                            // setIsRegistering(false);
                                                                            invalidateRegistration(false);
                                                                            doRegistrationValidation(false);
                                                                        }, 2000);
                                                                    }
                                                                });
                                                            })
                                                            .catch(error => {
                                                                setTimeout(()=>{
                                                                    console.warn(error);
                                                                    setInvalidRegistrationMsg('An Error Occurred');
                                                                    invalidateRegistration(true);
                                                                    doRegistrationValidation(false);
                                                                }, 2000);
                                                            });
                                                        });
                                                    });
                                                }
                                                else
                                                {
                                                    setTimeout(()=>{
                                                        if ( ! userFirstName ) { setInvalidRegistrationMsg( getLabel('CREATE_HINT_FIRSTNAME') +' '+ getLabel('REQUIRED') ); }
                                                        else if ( ! userLastName ) { setInvalidRegistrationMsg( getLabel('CREATE_HINT_LASTNAME') +' '+ getLabel('REQUIRED') ); }
                                                        else if ( ! userRegistrationEmail ) { setInvalidRegistrationMsg( getLabel('CREATE_HINT_EMAIL') +' '+ getLabel('REQUIRED') ); }
                                                        else if ( ! userPhone ) { setInvalidRegistrationMsg( getLabel('CREATE_HINT_MOBILE') +' '+ getLabel('REQUIRED') ); }

                                                        invalidateRegistration(true);
                                                        doRegistrationValidation(false);
                                                    }, 2000);
                                                }

                                            }, 250);
                                        }}
                                    />
                                </View>
                            }

                        </ScrollView>

                        <View style={tw`md:absolute bottom-5 md:bottom-8`}>
                            
                            <HorizontalRule style={tw`bg-black/20 w-screen my-5 md:my-8`} />

                            <Span style={tw`text-sm px-1 md:text-base text-center text-white`}>
                                { getLabel('CREATE_LOGIN_FOOTER_TEXT') }{" "}
                                
                                <Link 
                                    style={tw`text-white`} 
                                    onPress={()=>{setIsLoggingIn(true);setIsRegistering(false)}}
                                    accessibilityRole={ isLoggingIn ? false : "button" }
                                >{ getLabel('CREATE_LOGIN_FOOTER_LINK') }</Link>
                            </Span>
                            
                        </View>
    
                    </View>
                </AnimatedView>

            </View>

        </View>

        <Span style={tw`hidden md:flex md:w-80 absolute bottom-5 left-6 text-white/50 text-xs pointer-events-none`}>{copyright}</Span>

        <SelectMenu
            badge={false}
            title={ getLabel('SELECT') +' '+ getLabel('COUNTRY') }
            buttonTitle={ getLabel('SELECT') }
            active={userRegistrationCountryVisible}
            setActive={setUserRegistrationCountryVisible}
            selectedValue={userRegistrationCountry}
            options={countryOptions}
            onValueChange={(value) => {
                setUserRegistrationCountry(value);
                let foundCode = countryCodes.find(obj => {return parseInt(obj.value) === parseInt(value)})?.code;
                setUserPhonePrefix(foundCode);
            }}
            scrollable={true}
            searchable={true}
        />

    </View>
    );
}

export default WelcomeView;
