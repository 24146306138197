import React from 'react';
import { View, ScrollView, Image, Pressable } from 'react-native';
import { create, useDeviceContext } from 'twrnc';
import { Heading, PrimaryNav, Span, TextInput, SelectInput, PinInput, Button, Notification } from '../elements';
import { getInitials, getPropertiesByGroup, getLabel, getUnreadNotificationsCount, } from '../helpers';
import { GlobalContext } from '../global';

function EditAccountView ({navigation})
{
    const tw = create( require(`../../tailwind.config.js`) );    
    useDeviceContext(tw);
    
    let {
        apiUrl,
        apiVersion,
        loggedIn, setLoggedIn,
        userFirstName, setUserFirstName,
        userLastName, setUserLastName,
        userEmail, setUserEmail,
        userPhonePrefix, setUserPhonePrefix,
        userPhone, setUserPhone,
        userLangID, setUserLangID,
        userNotifications,
        themes, theme
    } = React.useContext(GlobalContext);

    React.useEffect(()=> 
    {        
        if ( ! loggedIn ) { navigation.navigate('loading'); }
    },[loggedIn, navigation]);

    const [userUpdatedFirstName, setUserUpdatedFirstName] = React.useState(userFirstName);
    const [userUpdatedLastName, setUserUpdatedLastName] = React.useState(userLastName);
    const [userUpdatedEmail, setUserUpdatedEmail] = React.useState(userEmail);
    const [userUpdatedPhonePrefix, setUserUpdatedPhonePrefix] = React.useState(userPhonePrefix);
    const [userUpdatedPhone, setUserUpdatedPhone] = React.useState(userPhone);
    const [userUpdatedLangID /*, setUserUpdatedLangID*/] = React.useState(userLangID);

    const [userUpdatedPhoneDisplay, setUserUpdatedPhoneDisplay]= React.useState(userPhone);
    const [userPhonePrefixDisplay, setUserPhonePrefixDisplay] = React.useState(userPhonePrefix);
    
    const [updateValidation, doUpdateValidation] = React.useState(false);
    const [invalidUpdateMsg, setInvalidUpdateMsg] = React.useState('');
    const [invalidUpdateMsgType, setInvalidUpdateMsgType] = React.useState('warning');
    const [userChangedEmail, setUserChangedEmail] = React.useState(false);
    const [userEmailCode, setUserEmailCode] = React.useState('');
    const [sidEmailCode, setSidEmailCode] = React.useState('');
    const [userChangedPhone, setUserChangedPhone] = React.useState(false);
    const [userPhoneCode, setUserPhoneCode] = React.useState('');
    const [sidPhoneCode, setSidPhoneCode] = React.useState('');
    
    const labels = React.useRef(null);
    React.useEffect(()=> 
    {
        if ( ! labels.current )
        {
            labels.current = {
                _ASSET: getLabel('ICON_ACCOUNT'),
                EDIT: getLabel('EDIT'),
                CREATE_HINT_FIRSTNAME: getLabel('CREATE_HINT_FIRSTNAME'),
                CREATE_HINT_LASTNAME: getLabel('CREATE_HINT_LASTNAME'),
                CREATE_HINT_MOBILE: getLabel('CREATE_HINT_MOBILE'),
                CREATE_HINT_EMAIL: getLabel('CREATE_HINT_EMAIL'),
                NOTIFICATIONS: getLabel('NOTIFICATIONS'),
                CANCEL: getLabel('CANCEL'),
                VERIFY_MOBILE_TITLE: getLabel('VERIFY_MOBILE_TITLE'),
                VERIFY_MOBILE_TEXT: getLabel('VERIFY_MOBILE_TEXT'),
                VERIFY_EMAIL_TITLE: getLabel('VERIFY_EMAIL_TITLE'),
                VERIFY_EMAIL_TEXT: getLabel('VERIFY_EMAIL_TEXT'),
                VERIFY_RESEND_CODE_TEXT: getLabel('VERIFY_RESEND_CODE_TEXT'),
                VERIFY_RESEND_CODE_LINK: getLabel('VERIFY_RESEND_CODE_LINK'),
                VERIFY_RESEND_CONFIRM_EMAIL: getLabel('VERIFY_RESEND_CONFIRM_EMAIL'),
                VERIFY_RESEND_CONFIRM_MOBILE: getLabel('VERIFY_RESEND_CONFIRM_MOBILE'),
                SAVE: getLabel('SAVE'),
                UPDATED: getLabel('UPDATED'),
            };
        }
    },[]);

    const [notificationCount, setNotificationCount] = React.useState(0);
    React.useEffect(()=>
    {
        setNotificationCount(getUnreadNotificationsCount())
    },[userNotifications]);

    return (
        <View label="editAccount" style={tw`bg-gray0 h-screen md:flex-row overflow-hidden max-w-screen`}>

            <PrimaryNav navigation={navigation} setLoggedIn={setLoggedIn} hideOnMobile={true} />

            <View style={tw`bg-[${themes[theme].brand_primary}] h-20 flex-row md:hidden`}>
                <View style={tw`bg-[${themes[theme].brand_primary}] h-20 p-5 pt-6.5 flex-row`}>
                    <Pressable
                        style={tw`p-2 -ml-2 -mt-2`}
                        onPress={() => {navigation.navigate('account')}}
                    >
                        <Image
                            accessibilityLabel={ labels.current?.CANCEL }
                            source={{ uri: require('../svg/back_white.svg') }}
                            resizeMode="contain"
                            style={tw`h-6 w-6 top-px mr-1`}
                        />
                    </Pressable>
                    <Heading style={tw`mt-1.5 leading-4 mb-0 text-white`} hlevel={1} styleHlevel={4}>
                        { labels.current?.EDIT } { labels.current?._ASSET }
                    </Heading>
                </View>

                <View style={tw`grow`}></View>
                
                <View style={tw`w-18 justify-center md:order-2 md:w-full`}>
                    <Pressable
                        onPress={()=>{navigation.navigate('notifications')}}
                        style={tw`p-5 relative md:flex-row md:pl-15 md:py-3 md:my-2`}
                    >
                        { notificationCount > 0 &&
                            <Span style={tw`bg-white text-[${themes[theme].brand_primary}] rounded-full min-w-4 h-4 text-center text-xs font-bold absolute bottom-1/2 left-1/2 md:left-18 z-10 pointer-events-none`}>
                                { notificationCount }
                            </Span>
                        }
                        <Image
                            accessibilityLabel={ labels.current?.NOTIFICATIONS }
                            source={{ uri: require('../svg/notifications.svg') }}
                            resizeMode="contain"
                            style={tw`h-5 md:h-8 w-full md:w-8 relative z-0 -left-1`}
                        />
                        
                        <Span style={tw`hidden md:flex font-bold text-base text-white leading-8 ml-4 tracking-wide`}>{ labels.current?.NOTIFICATIONS }</Span>
                        
                    </Pressable>
                </View>
            </View>
        
            <View style={tw`bg-[${themes[theme].brand_primary}] h-8 md:h-full md:w-10 z-10 md:z-0 -mb-3 md:mb-0 md:-mr-3`}>
                <View style={tw`bg-gray0 h-full w-full rounded-t-3xl md:rounded-l-3xl md:rounded-r-0 z-10 md:shadow-black md:shadow-opacity-10 md:shadow-radius-4`}></View>
            </View>

            <View style={[tw`bg-gray0 flex-grow md:pr-8 md:pl-4 z-20`, {width: tw.prefixMatch('md') ? 'calc(100% - 320px)' : '100%', height: tw.prefixMatch('md') ? '100%' : 'calc(100% - 112px)'}]}>

                <View style={tw`hidden md:flex h-13 px-5 pb-5 md:py-0 md:px-0 md:h-24 lg:h-33 flex-row md:items-center z-10`}>
                    <Pressable
                        style={tw`hidden md:flex p-2 -ml-2 -mt-2`}
                        onPress={() => {navigation.navigate('account')}}
                    >
                        <Image
                            accessibilityLabel="Cancel"
                            source={{ uri: tw.prefixMatch('md') ? require('../svg/'+theme+'/back.svg') : require('../svg/back_white.svg') }}
                            resizeMode="contain"
                            style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                        />
                    </Pressable>
                    
                    <Heading style={tw`mt-1.5 md:mt-0 leading-4 mb-0`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>
                        { labels.current?.EDIT } { labels.current?._ASSET }
                    </Heading>
                </View>

                <View style={tw`shrink`}>

                    { '' !== invalidUpdateMsg && 
                        <Notification
                            style={tw`my-4 mx-4 md:mx-0 w-11/12 md:w-80`}
                            type={invalidUpdateMsgType}
                        >{invalidUpdateMsg}</Notification>
                    }
                    
                    <ScrollView
                        contentContainerStyle={tw`w-full justify-start px-5 md:px-0`}
                    >
                        <View style={{width: tw.prefixMatch('md') ? '380px' : '100%', maxWidth:'100%',overflowX:'visible'}}>
                            <View style={tw`flex-row w-full items-center justify-start mt-2 mb-0`}>
                                <Span style={tw`shrink-0 bg-[${themes[theme].brand_primary}] text-white uppercase rounded-full text-3xl font-semibold mr-4 w-24 h-24 text-center leading-[96px]`}>
                                    { getInitials(userUpdatedFirstName +' '+ userUpdatedLastName) }
                                </Span>
                            </View>
    
                            <TextInput
                                style={tw`mt-5  rounded-br-0 rounded-bl-0`}
                                autoCapitalize="on"
                                image={ userUpdatedFirstName && userUpdatedLastName ? {uri: require('../svg/'+theme+'/user.svg')} : {uri: require('../svg/user_gray.svg')}}
                                placeholder={ labels.current?.CREATE_HINT_FIRSTNAME + '*' }
                                pattern="^.{2,}$"
                                defaultValue={userUpdatedFirstName}
                                validateGroup={updateValidation}
                                validatedValue={(value) => {setUserUpdatedFirstName(value)}}
                                required
                            />
                            
                            <TextInput
                                style={tw`border-t-0 rounded-tr-0 rounded-tl-0`}
                                styleFocus={tw`border-t`}
                                autoCapitalize="on"
                                image={{uri: require('../svg/user_white.svg')}}
                                placeholder={ labels.current?.CREATE_HINT_LASTNAME + '*' }
                                pattern="^.{2,}$"
                                defaultValue={userUpdatedLastName}
                                validateGroup={updateValidation}
                                validatedValue={(value) => {setUserUpdatedLastName(value)}}
                                required
                            />
                            
                            <TextInput
                                style={tw`mt-2`}
                                autoCapitalize="on"
                                image={ userUpdatedEmail ? {uri: require('../svg/'+theme+'/email_blue.svg')} : {uri: require('../svg/email_gray.svg')}}
                                placeholder={ labels.current?.CREATE_HINT_EMAIL + '*' }
                                pattern="^\S+@\S+\.\S+$"
                                defaultValue={userUpdatedEmail}
                                validateGroup={updateValidation}
                                validatedValue={(value) => {setUserUpdatedEmail(value)}}
                                required
                                onBlur={(e)=>{
                                    setInvalidUpdateMsg('');
                                    
                                    if ( e.target.value !== userEmail )
                                    {
                                        fetch(apiUrl, {
                                            method: 'POST',
                                            cache: 'no-cache',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({
                                                endpoint: 'user/update/email', v: apiVersion,
                                                client_key: localStorage.getItem('clientKey'),
                                                device_id: localStorage.getItem('deviceID'),
                                                payload: {
                                                    email: e.target.value,
                                                }
                                            }),
                                        })
                                        .then((response) => {
                                            return response.text().then(function(text) {
                                                const result = JSON.parse(text);
                                                // console.log(result);
                                                if ( result.error )
                                                {
                                                    if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                    setInvalidUpdateMsgType('warning');
                                                    setInvalidUpdateMsg(result.error);
                                                }
                                                else
                                                {
                                                    setUserChangedEmail(true);
                                                    setSidEmailCode(result.sid_email);
                                                }
                                            });
                                        })
                                        .catch(error => {
                                            console.warn(error);
                                            setInvalidUpdateMsgType('warning');
                                            setInvalidUpdateMsg('An Error Occurred');
                                        });
                                    }
                                }}
                            />
    
                            { userChangedEmail && 
    
                                <View>
                                    <Heading hlevel={4} style={tw`mt-4`}>{ labels.current?.VERIFY_EMAIL_TITLE }</Heading>
                                    <Span style={tw`mt-0`}>{ labels.current?.VERIFY_EMAIL_TEXT }</Span>
                                    <PinInput
                                        style={tw`ml-1 mt-4 mb-2 w-full md:w-80`}
                                        styleInput={tw`w-full`}
                                        styleFocus={tw`border-white`}
                                        keyboardType="numeric"
                                        pattern="^\d{4}$"
                                        validateOnChange={true}
                                        validateGroup={updateValidation}
                                        validatedValue={(value) => {setUserEmailCode(value)}}
                                        required
                                    />
                                </View>
                            }
        
                            <View style={tw`flex flex-row justify-between`}>
    
                                <SelectInput
                                    style={tw`mt-2 w-4/12 mr-2`}
                                    selectedValue={userPhonePrefixDisplay}
                                    onValueChange={(itemValue, itemIndex) =>
                                        setUserPhonePrefixDisplay(itemValue)
                                    }
                                    validatedValue={(value) => {setUserUpdatedPhonePrefix(value)}}
                                    options={getPropertiesByGroup( 'code', 'data.countries', 'code', (value)=>{return '+'+value;} )}
                                >
                                </SelectInput>
    
                                <TextInput
                                    style={tw`mt-2 w-8/12`}
                                    placeholder={ labels.current?.CREATE_HINT_MOBILE + '*' }
                                    placeholderTextColor='#FFFFFF60'
                                    keyboardType="phone-pad"
                                    // mask='(999) 999-9999'
                                    // value={userUpdatedPhoneDisplay}
                                    defaultValue={userUpdatedPhone}
                                    validatedValue={(value) => {setUserUpdatedPhone(value)}}
                                    onChangeText={(value)=>{setUserUpdatedPhoneDisplay(value)}}
                                    // pattern="^\(*[2-9]\d{2}\)*[-. /]*\d{3}[-. /]*\d{4} *e*x*t*\.* *\d{0,4}$"
                                    validateGroup={updateValidation}
                                    required
                                    onBlur={(e)=>{
                                        let value = '+'+userUpdatedPhonePrefix+' '+e.target.value;
                                        setInvalidUpdateMsg('');
                                        
                                        if ( e.target.value !== userPhone )
                                        {
                                            fetch(apiUrl, {
                                                method: 'POST',
                                                cache: 'no-cache',
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify({
                                                    endpoint: 'user/update/mobile', v: apiVersion,
                                                    client_key: localStorage.getItem('clientKey'),
                                                    device_id: localStorage.getItem('deviceID'),
                                                    payload: {
                                                        country_code: userUpdatedPhonePrefix,
                                                        mobile: e.target.value,
                                                    }
                                                }),
                                            })
                                            .then((response) => {
                                                return response.text().then(function(text) {
                                                    const result = JSON.parse(text);
                                                    // console.log(result);
                                                    if ( result.error )
                                                    {
                                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                        setInvalidUpdateMsgType('warning');
                                                        setInvalidUpdateMsg(result.error);
                                                    }
                                                    else
                                                    {
                                                        setUserChangedPhone(true);
                                                        setSidPhoneCode(result.sid_mobile);
                                                    }
                                                });
                                            })
                                            .catch(error => {
                                                console.warn(error);
                                                setInvalidUpdateMsgType('warning');
                                                setInvalidUpdateMsg('An Error Occurred');
                                            });
                                        }
                                    }}
                                />
                            
                            </View>
    
                            { userChangedPhone && 
    
                                <View>
                                    <Heading hlevel={4} style={tw`mt-4`}>{ labels.current?.VERIFY_MOBILE_TITLE }</Heading>
                                    <Span style={tw`mt-0`}>{ labels.current?.VERIFY_MOBILE_TEXT }</Span>
                                    <PinInput
                                        style={tw`ml-1 mt-4 mb-2 w-full md:w-80`}
                                        styleInput={tw`w-full`}
                                        styleFocus={tw`border-white`}
                                        keyboardType="numeric"
                                        pattern="^\d{4}$"
                                        validateOnChange={true}
                                        validateGroup={updateValidation}
                                        validatedValue={(value) => {setUserPhoneCode(value)}}
                                        required
                                    />
                                </View>
                            }
                        </View>
                        
                    </ScrollView>

                    <View style={{width: tw.prefixMatch('md') ? '380px' : '100%', maxWidth:'100%',overflowX:'visible'}}>
                        <Button
                            disabled={ 
                                ( (userUpdatedEmail !== userEmail || userChangedEmail) && (! sidEmailCode || ! userEmailCode) )
                                || ( (userUpdatedPhoneDisplay !== userPhone || userChangedPhone) && (! sidPhoneCode || ! userPhoneCode) )
                                ? true : false 
                            }
                            title={ labels.current?.SAVE }
                            style={tw`my-10 mx-5 md:mx-0`}
                            styleText={tw`text-sm tracking-[0.75]`}
                            validateGroup={updateValidation}
                            onPress={()=>{ 
                                if ( updateValidation ) { return; }
                                doUpdateValidation(true);
                                setInvalidUpdateMsg('');

                                setTimeout(() =>
                                {
                                    let canSubmit = true; 

                                    if ( ( userUpdatedEmail !== userEmail || userChangedEmail ) 
                                        && ( ! userEmailCode || ! sidEmailCode ) )
                                    {
                                        canSubmit = false;
                                    }

                                    if ( ( userUpdatedPhone !== userPhone || userChangedPhone ) 
                                        && ( ! userPhoneCode || ! sidPhoneCode ) )
                                    {
                                        canSubmit = false;
                                    }
                
                                    if ( ! canSubmit ) { return; }

                                    if ( userUpdatedEmail && userEmailCode && sidEmailCode )
                                    {
                                        fetch(apiUrl, {
                                            method: 'POST',
                                            cache: 'no-cache',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({
                                                endpoint: 'user/update/email', v: apiVersion,
                                                client_key: localStorage.getItem('clientKey'),
                                                device_id: localStorage.getItem('deviceID'),
                                                payload: {
                                                    code: userEmailCode,
                                                    sid: sidEmailCode,
                                                }
                                            }),
                                        })
                                        .then((response) => {
                                            return response.text().then(function(text) {
                                                const result = JSON.parse(text);
                                                if ( result.error ) 
                                                {
                                                    if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                    console.warn(result);
                                                    setInvalidUpdateMsgType('warning');
                                                    setInvalidUpdateMsg(result.error);
                                                    canSubmit = false;
                                                }
                                                else 
                                                {
                                                    setUserChangedEmail(false);
                                                    setUserEmail(userUpdatedEmail);
                                                    canSubmit = true;
                                                }
                                            });
                                        })
                                        .catch(error => {
                                            console.warn(error);
                                            setInvalidUpdateMsgType('warning');
                                            setInvalidUpdateMsg('An Error Occurred');
                                            canSubmit = false;
                                        });
                                    }
                
                                    if ( ! canSubmit ) { return; }

                                    if ( userUpdatedPhonePrefix && userUpdatedPhone && userPhoneCode && sidPhoneCode )
                                    {
                                        fetch(apiUrl, {
                                            method: 'POST',
                                            cache: 'no-cache',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({
                                                endpoint: 'user/update/mobile', v: apiVersion,
                                                client_key: localStorage.getItem('clientKey'),
                                                device_id: localStorage.getItem('deviceID'),
                                                payload: {
                                                    country_code: userUpdatedPhonePrefix,
                                                    mobile: userUpdatedPhone,
                                                    code: userPhoneCode,
                                                    sid: sidPhoneCode,
                                                }
                                            }),
                                        })
                                        .then((response) => {
                                            return response.text().then(function(text) {
                                                const result = JSON.parse(text);
                                                if ( result.error ) 
                                                {
                                                    if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                    setInvalidUpdateMsgType('warning');
                                                    setInvalidUpdateMsg(result.error);
                                                    canSubmit = false;
                                                }
                                                else 
                                                {
                                                    setUserChangedPhone(false);
                                                    setUserPhonePrefix(userUpdatedPhonePrefix);
                                                    setUserPhone(userUpdatedPhoneDisplay);
                                                    canSubmit = true;
                                                }
                                            });
                                        })
                                        .catch(error => {
                                            console.warn(error);
                                            setInvalidUpdateMsgType('warning');
                                            setInvalidUpdateMsg('An Error Occurred');
                                            canSubmit = false;
                                        });
                                    }
                
                                    if ( ! canSubmit ) { return; }

                                    if ( userUpdatedFirstName != userFirstName 
                                        || userUpdatedLastName != userLastName )
                                    {
                                        fetch(apiUrl, {
                                            method: 'POST',
                                            cache: 'no-cache',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({
                                                endpoint: 'user/update', v: apiVersion,
                                                client_key: localStorage.getItem('clientKey'),
                                                device_id: localStorage.getItem('deviceID'),
                                                payload: {
                                                    first_name : userUpdatedFirstName,
                                                    last_name : userUpdatedLastName,
                                                    lang_id : userUpdatedLangID
                                                }
                                            }),
                                        })
                                        .then((response) => {
                                            return response.text().then(function(text) {
                                                const result = JSON.parse(text);
                                                if ( result.error ) 
                                                {
                                                    if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                    setInvalidUpdateMsgType('warning');
                                                    setInvalidUpdateMsg(result.error);
                                                }
                                                else 
                                                {
                                                    setUserFirstName(userUpdatedFirstName);
                                                    setUserLastName(userUpdatedLastName);
                                                    setUserLangID(userUpdatedLangID);
                                                }
                                            });
                                        })
                                        .catch(error => {
                                            console.warn(error);
                                            setInvalidUpdateMsgType('warning');
                                            setInvalidUpdateMsg('An Error Occurred');
                                            canSubmit = false;
                                        });
                                    }

                                    let updatedUser = JSON.parse(localStorage.getItem('user'));
                                    updatedUser.name = userUpdatedFirstName +' '+ userUpdatedLastName;
                                    updatedUser.first_name = userUpdatedFirstName;
                                    updatedUser.last_name = userUpdatedLastName;
                                    updatedUser.email = userUpdatedEmail;
                                    updatedUser.country_code = userUpdatedPhonePrefix;
                                    updatedUser.mobile = userUpdatedPhone;
                                    updatedUser.lang_id = userUpdatedLangID;
                                    localStorage.setItem('user', JSON.stringify(updatedUser));
                                    
                                    setInvalidUpdateMsgType('success');
                                    setInvalidUpdateMsg(labels.current?._ASSET +' '+ labels.current?.UPDATED);
                                    doUpdateValidation(false);
                                }, 250);
                            }}
                        />
                    </View>
                    
                </View>
            </View>
        </View>
    )
}

export default EditAccountView;
