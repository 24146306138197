import React from 'react';
import { View, ScrollView, Pressable, Image, useWindowDimensions } from 'react-native';
import { create, useDeviceContext } from 'twrnc';
import { Heading, PrimaryNav, Span, TextInput, Button, AssetItem, SelectMenu, Modal, Notification, HorizontalRule, AddNewAssetButtons, DocumentUploadInput, SpecsCard, Tag } from '../elements';
import { useSpring, animated } from 'react-spring';
import { goPrevScreen, getLabel, getOptionsByLabelGroup, getPropertiesByGroup, truncate, now, is_date_in_past, is_date_within_30_days, date_DMJYYYY, YYYYMMDD_to_DMJYYYY, YYYYMMDD_to_MMDDYYYY, MMDDYYYY_to_YYYYMMDD, getUnreadNotificationsCount, sort_by, getRouteFilter, updateRouteFilter, canIhazIcon, processDocumentInput, uploadDocument, getAssetDocuments } from '../helpers';
import { GlobalContext } from '../global';
import { useToast } from 'react-native-toast-notifications';

function YachtDocumentsView ({route, navigation})
{
    const AnimatedView = animated(View);
    const tw = create( require(`../../tailwind.config.js`) );
    useDeviceContext(tw);
    const {height, width} = useWindowDimensions();
    const formHeight = height - 100;
    const toast = useToast();

    let {
        apiUrl,
        apiVersion,
        settings,
        setLastUpdate,
        loggedIn, setLoggedIn,
        userYachts,
        userNotifications,
        enabledFeatures,
        themes, theme
    } = React.useContext(GlobalContext);

    React.useEffect(()=>
    {
        if ( ! loggedIn ) { navigation.navigate('loading'); }
    },[loggedIn, navigation]);

    const [getFilter, setFilter] = React.useState(null);
    const [filterVisible, setFilterVisible] = React.useState(false);

    const labels = React.useRef(null);
    React.useEffect(()=>
    {
        if ( ! labels.current )
        {
            labels.current = {
                _ASSETS: getLabel('DOCUMENTS'),
                _ASSET: getLabel('DOCUMENT'),
                _404_TEXT: getLabel('DOCUMENT_404_TEXT'),

                VIEW: getLabel('VIEW'),
                EDIT: getLabel('EDIT'),
                DELETE: getLabel('DELETE'),
                CANCEL: getLabel('CANCEL'),
                NOTIFICATIONS: getLabel('NOTIFICATIONS'),
                CONTENT_ADD: getLabel('CONTENT_ADD'),
                ADD: getLabel('ADD'),
                UPDATE: getLabel('UPDATE'),
                SORT: getLabel('SORT'),
                ALL: getLabel('ALL'),
                REQUIRED: getLabel('REQUIRED'),
                TYPE: getLabel('TYPE'),
                SELECT: getLabel('SELECT'),

                DELETE_CONFIRM_TITLE: getLabel('DELETE_CONFIRM_TITLE'),
                DELETE_CONFIRM_BODY: getLabel('DELETE_CONFIRM_BODY'),
                PERMANENT_ACTION: getLabel('PERMANENT_ACTION'),

                TOAST_ADD: getLabel('TOAST_ADD'),
                TOAST_UPDATE: getLabel('TOAST_UPDATE'),
                TOAST_DELETE: getLabel('TOAST_DELETE'),

                DOCUMENT_FILE: getLabel('ATTACHMENT_FILE'),
                DOCUMENT_TOO_LARGE_BODY: getLabel('ATTACHMENT_TOO_LARGE_BODY'),
                DATE: getLabel('DATE'),
                NAME: getLabel('NAME'),
                EXPIRES: getLabel('EXPIRES'),
                EXPIRING: getLabel('EXPIRING'),
                EXPIRED: getLabel('EXPIRED'),
                EXPIRATION_NONE: getLabel('EXPIRATION_NONE'),
                EXPIRATION_VALID: getLabel('EXPIRATION_VALID'),
                EXPIRATION: getLabel('EXPIRATION'),
                DETAILS: getLabel('DETAILS'),
                DETAIL: getLabel('DETAIL'),
            };
        }
        setFilter(getRouteFilter('documents') || getLabel('ALL'));
    },[]);

    const [notificationCount, setNotificationCount] = React.useState(0);
    React.useEffect(()=>
    {
        setNotificationCount(getUnreadNotificationsCount())
    },[userNotifications]);

    const { yachtID } = route.params;
    const [ yacht, setYacht ] = React.useState(0);

    // ref for photo type
    const photoObjectType = React.useRef('obj_vessel');

    const [primaryMsg, setPrimaryMsg] = React.useState('');
    const [primaryMsgType, setPrimaryMsgType] = React.useState('warning');
    const [modalActive, setModalActive] = React.useState(false);
    const [modalAffectID, setModalAffectID] = React.useState('');

    const [addingNew, setAddingNew] = React.useState(false);
    const [updateDocumentID, setUpdateDocumentID] = React.useState(0);

    const [viewingAsset, setViewingAsset] = React.useState(false);
    const [viewingAssetObj, setViewingAssetObj] = React.useState({});

    const [newAssetMsg, setNewAssetMsg] = React.useState('');
    const [newAssetMsgType, setNewAssetMsgType] = React.useState('warning');
    const [newAssetValidation, doNewAssetValidation] = React.useState(false);
    const [getAssets, setAssets] = React.useState([]);

    // document specific inputs
    const [documentName, setDocumentName] = React.useState('');
    const [documentDesc, setDocumentDesc] = React.useState('');
    const [documentType, setDocumentType] = React.useState(0);
    const [documentExpiration, setDocumentExpiration] = React.useState('');
    const [documentExpirationMasked, setDocumentExpirationMasked] = React.useState('');
    const [documentTypeSelectVisible, setDocumentTypeSelectVisible] = React.useState(false);
    const [documents, setDocuments] = React.useState([]);
    const [documentData, setDocumentData] = React.useState('');
    const documentMimeType = React.useRef('');
    const documentSize = React.useRef(0);
    const documentExt = React.useRef('');
    const documentInput = React.useRef(null);
    const documentNameInput = React.useRef(null);
    const documentFileNameInput = React.useRef(null);
    const documentDescInput = React.useRef(null);
    const documentExpirationInput = React.useRef(null);
    const documentTypeOptions = getOptionsByLabelGroup('types.attachment.types', true);
    const documentTypeIcons = getPropertiesByGroup( 'value', 'types.attachment.types', 'icon', false, true );

    // only for this documents view
    const yachtDocuments = getAssetDocuments( yachtID, yachtID, userYachts );
    const documentTypeFilterOptions = [{value: getLabel('ALL'), label: getLabel('ALL')}].concat(documentTypeOptions);

    React.useEffect(() => {
        let assets = [];

        if ( yachtID )
        {
            let yachtObj = false;
            userYachts.every((value, index) => {
                if ( yachtID == value.id ) { setYacht(value); yachtObj = value; return false; }
                return true;
            });

            let documents = [];

            if ( yachtDocuments.length > 0 )
            {
                switch(getFilter)
                {
                    case labels.current?.ALL:
                        updateRouteFilter('documents', labels.current?.ALL);
                        documents = [...yachtDocuments].sort(sort_by('modified_date', true));
                        break;

                    default:
                        updateRouteFilter('documents', getFilter);
                        documents = yachtDocuments.filter(function (asset)
                        {
                            return asset.type_id === getFilter;
                        });
                        documents = [...documents].sort(sort_by('modified_date', true));
                        break;
                }

                documents.forEach((file, index) => {
                    let icon = file.type_id ? documentTypeIcons.find(obj => {return obj.value == file.type_id})?.icon : 'miscellaneous';
                    let icon_variant = '_white';
                    icon = icon + icon_variant;
                    icon = canIhazIcon( icon ) ? icon : 'miscellaneous' + icon_variant;
                    file.expiration_tag = file.expiration_date ? ( is_date_within_30_days(file.expiration_date) ? ((is_date_in_past(file.expiration_date) ? labels?.current?.EXPIRED : labels?.current?.EXPIRING) ) : labels?.current?.EXPIRATION_VALID ) : labels?.current?.EXPIRATION_NONE;
                    file.expiration_color = file.expiration_date ? ( is_date_within_30_days(file.expiration_date) ? ((is_date_in_past(file.expiration_date) ? 'red' : 'orange') ) : 'green' ) : 'gray5_5';

                    assets.push(
                        <AssetItem
                            key={index}
                            minHeight={73}
                            color={themes[theme].brand_primary}
                            icon={{ uri: require('../svg/'+icon+'.svg') }}
                            title={ file.type_id ? documentTypeOptions.find(obj => {return obj.value == file.type_id})?.label : '' }
                            description={ file.name }
                            description2={ file.expiration_date ? file.expiration_tag +': '+YYYYMMDD_to_DMJYYYY(file.expiration_date) : ' ' }
                            tag={ file.expiration_tag }
                            tagColor={ file.expiration_color }
                            // description2={ file.mime_type }
                            menu={[
                                { name:labels.current?.EDIT, color:'green', onPress: ()=>{
                                    setUpdateDocumentID(file.id);
                                    setDocumentName(file.name); documentNameInput.current.value = file.name;
                                    setDocumentDesc(file.details); documentDescInput.current.value = file.details;
                                    setDocumentExpiration(YYYYMMDD_to_MMDDYYYY(file.expiration_date)); documentExpirationInput.current.value = YYYYMMDD_to_MMDDYYYY(file.expiration_date);
                                    setDocumentExpirationMasked(YYYYMMDD_to_MMDDYYYY(file.expiration_date));
                                    setDocumentType(file.type_id);
                                    setAddingNew(true);
                                }},
                                { name:labels.current?.DELETE, color:'red', onPress: ()=>{
                                    setModalAffectID(file.id);
                                    documentMimeType.current = file.mime_type;
                                    setModalActive(true);
                                }}
                            ]}
                            onPress={() => {
                                setViewingAssetObj(file);
                                setViewingAsset(true);
                            }}
                            viewMore={labels.current?.VIEW}
                        />
                    )
                });
            }
        }

        setAssets(assets);
    },[getFilter, yachtID, userYachts, navigation, setAssets, labels]);

    let addNewPaneAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: addingNew ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: addingNew ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    let addNewPaneAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
        },
        to: {
            opacity: addingNew ? 1 : 0,
            pointerEvents: addingNew ? 'auto' : 'none',
        },
        config: addingNew ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    addNewPaneAnimation = tw.prefixMatch('md') ? addNewPaneAnimationDesktop : addNewPaneAnimation;

    let viewDocumentAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: viewingAsset ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: viewingAsset ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });
    let viewDocumentAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
        },
        to: {
            opacity: viewingAsset ? 1 : 0,
            pointerEvents: viewingAsset ? 'auto' : 'none',
        },
        config: viewingAsset ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });
    viewDocumentAnimation = tw.prefixMatch('md') ? viewDocumentAnimationDesktop : viewDocumentAnimation;

    return (
        <View label="yachtDocuments" style={tw`bg-gray0 h-screen md:flex-row overflow-hidden max-w-screen`}>

            <PrimaryNav navigation={navigation} setLoggedIn={setLoggedIn} hideOnMobile={true} yachtID={yachtID} />

            <View style={tw`bg-[${themes[theme].brand_primary}] h-20 flex-row md:hidden`}>
                <View style={tw`bg-[${themes[theme].brand_primary}] h-20 p-5 pt-6.5 flex-row`}>
                    <Pressable
                        style={tw`p-2 -ml-2 -mt-2`}
                        onPress={() => { goPrevScreen(navigation, 'yacht', {yachtID: yachtID}) }}
                    >
                        <Image
                            accessibilityLabel={ labels.current?.CANCEL }
                            source={{ uri: require('../svg/back_white.svg') }}
                            resizeMode="contain"
                            style={tw`h-6 w-6 top-px mr-1`}
                        />
                    </Pressable>
                    <Span style={tw`bg-${yacht.color || 'gray6'} text-white uppercase rounded-full text-sm font-semibold shrink-0 w-8 h-8 mr-3 text-center leading-[31px] -mt-[2px]`}>{yacht.initials}</Span>
                    <Heading style={tw`mt-1.5 leading-4 mb-0 text-white`} hlevel={1} styleHlevel={4}>
                        { labels.current?._ASSETS }
                    </Heading>
                </View>

                <View style={tw`grow`}></View>

                <View style={tw`w-18 justify-center md:order-2 md:w-full`}>
                    <Pressable
                        onPress={()=>{navigation.navigate('notifications')}}
                        style={tw`p-5 relative md:flex-row md:pl-15 md:py-3 md:my-2`}
                    >
                        { notificationCount > 0 &&
                            <Span style={tw`bg-white text-[${themes[theme].brand_primary}] rounded-full min-w-4 h-4 text-center text-xs font-bold absolute bottom-1/2 left-1/2 md:left-18 z-10 pointer-events-none`}>
                                { notificationCount }
                            </Span>
                        }

                        <Image
                            accessibilityLabel={ labels.current?.NOTIFICATIONS }
                            source={{ uri: require('../svg/notifications.svg') }}
                            resizeMode="contain"
                            style={tw`h-5 md:h-8 w-full md:w-8 relative z-0 -left-1`}
                        />

                        <Span style={tw`hidden md:flex font-bold text-base text-white leading-8 ml-4 tracking-wide`}>{ labels.current?.NOTIFICATIONS }</Span>

                    </Pressable>
                </View>
            </View>

            <View style={tw`bg-[${themes[theme].brand_primary}] h-8 md:h-full md:w-10 z-0 -mb-3 md:mb-0 md:-mr-3`}>
                <View style={tw`bg-gray0 h-full w-full rounded-t-3xl md:rounded-l-3xl md:rounded-r-0 z-10 md:shadow-black md:shadow-opacity-10 md:shadow-radius-4`}></View>
            </View>

            <View style={[tw`bg-gray0 grow md:pr-8 md:pl-4 z-20`, {width: tw.prefixMatch('md') ? 'calc(100% - 320px)' : '100%', height: tw.prefixMatch('md') ? '100%' : 'calc(100% - 112px)'}]}>

                { ! tw.prefixMatch('md') && 'object' === typeof yacht && yachtDocuments?.length > 0 &&
                    <View style={tw`h-13 px-5 pb-5 md:py-0 md:px-0 md:h-24 lg:h-33 flex-row justify-end md:items-center z-10`}>

                        { /* <TextInput
                            style={tw`shrink-1 mr-5 p-1.5 max-w-60`}
                            image={{ uri: require('../svg/search_gray.svg') }}
                            placeholder="Search"
                            // value={filterText}
                            // onChangeText={(value)=>{setFilterText(value)}}
                        /> */ }

                        <Pressable
                            style={tw`border border-gray3 py-1 px-2 md:py-1.5 md:px-3 rounded flex-row md:mr-4 md:min-w-60`}
                            onPress={()=>{setFilterVisible(true)}}
                        >
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/sort.svg') }}
                                resizeMode="contain"
                                style={tw`h-4 w-4 top-0.5 md:top-1 mr-2`}
                            />

                            <Span style={tw`text-sm md:text-base leading-4`}>{ labels.current?.VIEW }: <Span style={tw`text-sm md:text-base font-medium`}>{labels.current?.ALL !== getFilter ? documentTypeFilterOptions.find(obj => {return obj.value == getFilter})?.label : getFilter}</Span></Span>

                        </Pressable>

                    </View>
                }

                <View style={! getAssets.length ? tw`grow` : tw`shrink`}>
                    <ScrollView
                        centerContent={getAssets.length ? false : true}
                        style={{width:'100%',overflowX:'visible'}}
                        contentContainerStyle={tw`w-full min-h-full justify-start`}
                    >
                        <View style={tw`hidden md:flex`}>
                            <View style={tw`flex-row w-full items-center justify-between lg:justify-start mt-2 md:mt-6 lg:mt-10 mb-0 md:mb-1 lg:mb-5 md:px-1`}>
                                <Span style={tw`bg-${yacht.color || 'gray6'} text-white uppercase rounded-full text-lg font-semibold shrink-0 w-14 h-14 mr-3 text-center leading-[56px]`}>{yacht.initials}</Span>

                                <Heading style={tw`leading-4 hidden md:flex`} hlevel={1} styleHlevel={2}>
                                    {yacht.name}
                                </Heading>

                                { 'object' === typeof yacht && yachtDocuments?.length > 0 &&
                                    <View style={tw`px-5 md:py-0 md:px-0 flex-row md:items-center z-10`}>

                                        { /* <TextInput
                                            style={tw`shrink-1 mr-5 p-1.5 max-w-60`}
                                            image={{ uri: require('../svg/search_gray.svg') }}
                                            placeholder="Search"
                                            // value={filterText}
                                            // onChangeText={(value)=>{setFilterText(value)}}
                                        /> */ }

                                        <Pressable
                                            style={tw`border border-gray3 py-1 px-2 md:py-1.5 md:px-3 rounded flex-row md:mr-4 md:min-w-60`}
                                            onPress={()=>{setFilterVisible(true)}}
                                        >
                                            <Image
                                                accessibilityHidden={true}
                                                source={{ uri: require('../svg/sort.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-4 w-4 top-0.5 md:top-1 mr-2`}
                                            />

                                            <Span style={tw`text-sm md:text-base leading-4`}>{ labels.current?.VIEW }: <Span style={tw`text-sm md:text-base font-medium`}>{labels.current?.ALL !== getFilter ? documentTypeFilterOptions.find(obj => {return obj.value == getFilter})?.label : getFilter}</Span></Span>

                                        </Pressable>

                                    </View>
                                }
                            </View>
                        </View>

                        { '' !== primaryMsg &&
                            <View style={tw`w-full px-4 md:px-1`}>
                                <Notification
                                    style={tw`my-4`}
                                    type={primaryMsgType}
                                >{primaryMsg}</Notification>
                            </View>
                        }
                        { ! yachtDocuments?.length &&
                            <View style={tw`justify-center grow items-center`}>

                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('../svg/attachment_lightgray.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-32 w-32 md:h-48 md:w-48`}
                                />
                                <Heading style={tw`text-gray5 text-center p-5 mb-26`} hlevel={tw.prefixMatch('md') ? 2 : 3}>{ yachtDocuments?.length > 0 ? getLabel('SEARCH_NOTFOUND_TITLE') : labels.current?._404_TEXT }</Heading>

                                <View style={tw`fixed bottom-9 md:bottom-18 right-18 pointer-events-none`}>

                                    <Span style={tw`text-sm md:text-base font-medium font-sans mb-2 mr-6`}>{ labels.current?.CONTENT_ADD }</Span>

                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/'+theme+'/arrow_organic.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-9 w-18 left-14 md:left-16`}
                                    />
                                </View>
                            </View>
                        }

                        { yachtDocuments?.length > 0 &&
                            <View>
                                <Heading hlevel={2} styleHlevel={4} style={tw`hidden md:flex pl-1`}>{ labels.current?._ASSETS }</Heading>

                                <View style={tw`md:pl-1 md:flex-wrap md:flex-row mb-20 md:justify-start md:items-start`}>
                                    {getAssets}
                                </View>
                            </View>
                        }

                    </ScrollView>

                    <DocumentUploadInput
                        passedRef={documentInput}
                        onChange={(e)=>{
                            if ( ! e.target.files[0] ) { return; }
                            // if size is > 30 MB
                            if ( e.target.files[0].size > ( settings?.max_size_document ? Math.round(parseInt(settings.max_size_document, 10)) : 31457280 ) ) { toast.show(labels.current?.DOCUMENT_TOO_LARGE_BODY.replace('%size%', '30 MB'), {type: 'danger', duration:10000}); return; }

                            documentFileNameInput.current.value = e.target.files[0].name;
                            processDocumentInput(e.target.files[0]).then((result)=>{
                                setDocumentData(result);
                                documentMimeType.current = e.target.files[0].type;
                                documentSize.current = e.target.files[0].size;
                                documentExt.current = e.target.files[0].name.split('.').pop().toLowerCase();
                            }).catch((error)=>{
                                toast.show(error, {type: 'danger', duration:10000});
                            });
                        }}
                    />

                    <AddNewAssetButtons addNewLabel={ labels.current?.ADD +' '+ labels.current?._ASSET } onAddNew={()=>{
                        setUpdateDocumentID(0);

                        setDocumentData('');
                        documentSize.current = 0;
                        documentExt.current = '';
                        if ( documentFileNameInput.current?.value ) { documentFileNameInput.current.value = ''; }
                        documentMimeType.current = '';
                        setDocumentName('');
                        if ( documentNameInput.current?.value ) { documentNameInput.current.value = ''; }
                        setDocumentExpiration('');
                        setDocumentExpirationMasked('');
                        if ( documentExpirationInput.current?.value ) { documentExpirationInput.current.value = ''; }
                        setDocumentType(0);
                        setDocumentDesc('');
                        if ( documentDescInput.current?.value ) { documentDescInput.current.value = ''; }

                        setAddingNew(true);
                    }} />
                </View>
            </View>

            <AnimatedView style={addNewPaneAnimation}>

                <Pressable style={tw`hidden md:flex bg-[${themes[theme].brand_primary}]/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setNewAssetMsg(''); setAddingNew(false)}}
                ></Pressable>

                <View style={tw`md:w-108 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>

                    <View style={tw`bg-[${themes[theme].brand_primary}] md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setNewAssetMsg(''); setAddingNew(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/'+theme+'/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 md:mt-0 leading-4 mb-0 text-white md:text-ink`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ ( 0 !== updateDocumentID ? labels.current?.EDIT : labels.current?.ADD ) +' '+ labels.current?._ASSET }</Heading>
                    </View>

                    <View style={tw`bg-[${themes[theme].brand_primary}] h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>

                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : formHeight}]}>
                        <View style={tw`h-full md:h-max`}>
                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5 pb-5 md:pb-0`}
                            >
                                { '' !== newAssetMsg &&
                                    <View style={tw`w-full md:pr-2`}>
                                        <Notification
                                            style={tw`my-2`}
                                            type={newAssetMsgType}
                                        >{newAssetMsg}</Notification>
                                    </View>
                                }

                                <View style={tw`md:flex-row md:flex-wrap md:justify-between`}>

                                    <Pressable
                                        style={tw`mt-2 w-full`}
                                        onPress={()=>{setDocumentTypeSelectVisible(true)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            placeholder={ labels.current?.TYPE +'*' }
                                            image={ documentType ? { uri: require('../svg/'+theme+'/attachment.svg') } : { uri: require('../svg/attachment_gray.svg') }}
                                            // defaultValue={documentType}
                                            value={documentType ? documentTypeOptions.find(obj => {return obj.value === documentType})?.label : ''}
                                            validateGroup={newAssetValidation}
                                            required
                                        />

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>

                                    <TextInput
                                        style={tw`mt-2 w-full`}
                                        placeholder={ labels.current?.NAME +'*' }
                                        image={ documentNameInput.current?.value ? { uri: require('../svg/'+theme+'/name.svg') } : { uri: require('../svg/name_gray.svg') }}
                                        validatedValue={(value) => {setDocumentName(value)}}
                                        validateOnChange={true}
                                        validateGroup={newAssetValidation}
                                        stateValue={documentName}
                                        passedRef={documentNameInput}
                                        required
                                    />

                                    <Span style={tw`text-sm text-gray6 my-2 w-full`}>{ '* ' + labels.current?.REQUIRED }</Span>

                                    <TextInput
                                        style={tw`mt-2 w-full`}
                                        autoCapitalize="on"
                                        placeholder={ labels.current?.DETAIL }
                                        image={ documentDescInput.current?.value ? { uri: require('../svg/'+theme+'/description.svg') } : { uri: require('../svg/description_gray.svg') }}
                                        pattern="^.{2,}$"
                                        validatedValue={(value) => {setDocumentDesc(value)}}
                                        validateOnChange={true}
                                        validateGroup={newAssetValidation}
                                        stateValue={documentDesc}
                                        passedRef={documentDescInput}
                                    />

                                    { 0 === updateDocumentID &&
                                        <Pressable
                                            style={tw`mt-2 w-full`}
                                            onPress={()=>{documentInput.current.click();}}
                                        >
                                            <TextInput
                                                style={tw`pointer-events-none`}
                                                placeholder={ labels.current?.SELECT +' '+ labels.current?.DOCUMENT_FILE }
                                                image={ documentData ? { uri: require('../svg/'+theme+'/upload.svg') } : { uri: require('../svg/upload_gray.svg') }}
                                                // defaultValue={documentType}
                                                passedRef={documentFileNameInput}
                                            />

                                            <Image
                                                accessibilityHidden={true}
                                                source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                            />
                                        </Pressable>
                                    }

                                    <TextInput
                                        style={tw`mt-2 w-full`}
                                        keyboardType="numeric"
                                        mask="99/99/9999"
                                        autoCapitalize="off"
                                        placeholder={labels.current?.DATE +" "+ labels.current?.EXPIRES +" MM/DD/YYYY"}
                                        image={ documentExpiration ? { uri: require('../svg/'+theme+'/calendar_blue.svg') } : { uri: require('../svg/calendar_gray.svg') }}
                                        pattern="^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)[0-9]{2}$"
                                        allowPastDates={ true }
                                        validateGroup={newAssetValidation}
                                        validatedValue={(value) => {setDocumentExpiration(value)}}
                                        validateOnChange={true}
                                        stateValue={documentExpiration}
                                        passedRef={documentExpirationInput}
                                        value={documentExpirationMasked}
                                        onChangeText={(value)=>{setDocumentExpirationMasked(value)}}
                                        required
                                    />

                                </View>

                            </ScrollView>

                            <HorizontalRule style={tw`md:hidden`} />

                            <Button
                                style={tw`m-5`}
                                title={0 !== updateDocumentID ? labels.current?.UPDATE : labels.current?.ADD}
                                disabled={documentName && documentType ? false : true}
                                validateGroup={newAssetValidation}
                                onPress={()=>{

                                    if ( newAssetValidation ) { return; }

                                    doNewAssetValidation(true);
                                    setNewAssetMsg('');

                                    setTimeout(()=>
                                    {
                                        // check if fields are valid

                                        if ( ! documentType
                                            || ! documentName
                                        ){
                                            let errorMsg = [];

                                            if ( ! documentType )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?._ASSET +' '+labels.current?.TYPE); }

                                            if ( ! documentName )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?._ASSET +' '+labels.current?.NAME); }

                                            setNewAssetMsgType('warning');
                                            setNewAssetMsg(errorMsg.join('\n'));
                                            doNewAssetValidation(false);
                                        }
                                        else
                                        {
                                            if ( 0 === updateDocumentID )
                                            {
                                                uploadDocument(
                                                    documentData,
                                                    documentExt.current,
                                                    documentSize.current,
                                                    documentType,
                                                    documentName,
                                                    documentDesc || '',
                                                    MMDDYYYY_to_YYYYMMDD(documentExpirationMasked),
                                                    photoObjectType.current,
                                                    yachtID,
                                                    yachtID,
                                                    toast,
                                                    0 === updateDocumentID ? labels.current?.TOAST_ADD.replace('{object}',labels.current?._ASSET) : labels.current?.TOAST_UPDATE.replace('{object}',labels.current?._ASSET),
                                                    {apiUrl:apiUrl, apiVersion:apiVersion, setLoggedIn:setLoggedIn},
                                                    (status)=>{
                                                        doNewAssetValidation(false);
                                                        if ( 'success' === status )
                                                        {
                                                            setLastUpdate(now());

                                                            setAddingNew(false);
                                                            setTimeout(()=>{
                                                                setUpdateDocumentID(0);
                                                                setDocumentData('');
                                                                if ( documentFileNameInput.current?.value ) { documentFileNameInput.current.value = ''; }
                                                                setDocumentType(0);
                                                                documentMimeType.current = '';
                                                                setDocumentName('');
                                                                if ( documentNameInput.current?.value ) { documentNameInput.current.value = ''; }
                                                                setDocumentDesc('');
                                                                if ( documentDescInput.current?.value ) { documentDescInput.current.value = ''; }
                                                                setDocumentExpiration('');
                                                                setDocumentExpirationMasked('');
                                                                if ( documentExpirationInput.current?.value ) { documentExpirationInput.current.value = ''; }
                                                            }, 1500);
                                                        }
                                                    }
                                                );
                                            }
                                            else {
                                                let payload = {
                                                    id: updateDocumentID,
                                                    name: documentName,
                                                    type_id: documentType,
                                                    details: documentDesc,
                                                    expiration_date: MMDDYYYY_to_YYYYMMDD(documentExpirationMasked)
                                                };

                                                fetch(apiUrl, {
                                                    method: 'POST',
                                                    cache: 'no-cache',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify({
                                                        endpoint: 'documents/update', v: apiVersion,
                                                        device_id: localStorage.getItem('deviceID'),
                                                        client_key: localStorage.getItem('clientKey'),
                                                        payload: payload
                                                    }),
                                                })
                                                .then((response) => {
                                                    return response.text().then(function(text) {
                                                        const result = JSON.parse(text);
                                                        if ( result.error )
                                                        {
                                                            if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                            setNewAssetMsgType('warning');
                                                            setNewAssetMsg(result.error);
                                                            doNewAssetValidation(false);
                                                        }
                                                        else
                                                        {
                                                            setAddingNew(false);
                                                            toast.show((0 !== updateDocumentID ? labels.current?.TOAST_UPDATE : labels.current?.TOAST_ADD).replace('{object}',labels.current?._ASSET), {type: 'success', duration:2000});

                                                            setTimeout(()=>{
                                                                setLastUpdate(now()); // forces app to refetch all account data
                                                            }, 500);

                                                            setTimeout(()=>{
                                                                doNewAssetValidation(false);
                                                                setUpdateDocumentID(0);
                                                                setDocumentData('');
                                                                if ( documentFileNameInput.current?.value ) { documentFileNameInput.current.value = ''; }
                                                                setDocumentType(0);
                                                                documentMimeType.current = '';
                                                                setDocumentName('');
                                                                if ( documentNameInput.current?.value ) { documentNameInput.current.value = ''; }
                                                                setDocumentDesc('');
                                                                if ( documentDescInput.current?.value ) { documentDescInput.current.value = ''; }
                                                                setDocumentExpiration('');
                                                                setDocumentExpirationMasked('');
                                                                if ( documentExpirationInput.current?.value ) { documentExpirationInput.current.value = ''; }
                                                            }, 1500);
                                                        }
                                                    });
                                                })
                                                .catch(error => {
                                                    console.warn(error);
                                                    setNewAssetMsgType('warning');
                                                    if ( ! window.navigator.onLine ) { setNewAssetMsg('Internet connection failed. Please try again.'); }
                                                    else { setNewAssetMsg('An error ocurred. See console log.'); }
                                                    doNewAssetValidation(false);
                                                });
                                            }
                                        }
                                    }, 250);


                                }}
                            />
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <AnimatedView style={viewDocumentAnimation}>

                <Pressable style={tw`hidden md:flex bg-[${themes[theme].brand_primary}]/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setViewingAsset(false)}}
                ></Pressable>

                <View style={tw`md:w-172 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>

                    <View style={tw`bg-[${themes[theme].brand_primary}] md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setViewingAsset(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/'+theme+'/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 leading-4 mb-0 text-white md:hidden`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ labels.current?._ASSET }</Heading>
                        <View style={tw`justify-between hidden md:flex md:flex-row grow mt-0 mb-0 items-start h-full`}>
                            <View style={tw`self-center`}>
                                <Heading style={tw`leading-4 mt-0 mb-0 text-ink`} hlevel={1} styleHlevel={2}>{ viewingAssetObj.type_id ? documentTypeOptions.find(obj => {return obj.value == viewingAssetObj.type_id})?.label : '' }</Heading>
                            </View>

                            <View style={tw`self-center`}>
                                <Tag style={tw`self-end mb-0`} color={viewingAssetObj.expiration_color}>{ viewingAssetObj.expiration_tag }</Tag>

                                { null !== viewingAssetObj.expiration_date &&
                                    <View style={tw`flex-row items-center`}>
                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/date.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-4 w-4 mr-2`}
                                        />
                                        <Span style={tw`text-gray6 text-sm leading-6 text-right`}>{ ( is_date_in_past(viewingAssetObj.expiration_tag) ? labels?.current?.EXPIRED : labels?.current?.EXPIRES ) + ' ' + YYYYMMDD_to_DMJYYYY( viewingAssetObj.expiration_date ) }</Span>
                                    </View>
                                }
                            </View>
                        </View>
                    </View>

                    <View style={tw`bg-[${themes[theme].brand_primary}] h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>

                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : 'calc(100vh - 100px)'}]}>
                        <View style={tw`h-full md:h-max`}>
                            <View style={tw`w-full justify-between px-5 mb-4 flex-row md:hidden`}>
                                <View style={tw`flex-col`}>
                                    <Span style={tw`font-bold`}>{ viewingAssetObj.type_id ? documentTypeOptions.find(obj => {return obj.value == viewingAssetObj.type_id})?.label : '' }</Span>

                                    { null !== viewingAssetObj.expiration_date &&
                                        <View style={tw`flex-row items-center`}>
                                            <Image
                                                accessibilityHidden={true}
                                                source={{ uri: require('../svg/date.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-4 w-4 mr-2`}
                                            />
                                            <Span style={tw`text-gray6 text-sm leading-6 text-right`}>{ ( is_date_in_past(viewingAssetObj.expiration_tag) ? labels?.current?.EXPIRED : labels?.current?.EXPIRES ) + ' ' + YYYYMMDD_to_DMJYYYY( viewingAssetObj.expiration_date ) }</Span>
                                        </View>
                                    }
                                </View>

                                <View style={tw`self-center`}>
                                    <Tag style={tw`self-end mb-0`} color={viewingAssetObj.expiration_color}>{ viewingAssetObj.expiration_tag }</Tag>
                                </View>
                            </View>
                            <HorizontalRule style={tw`mb-4 md:hidden`} />

                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5`}
                            >
                                <View style={tw`md:flex-row md:flex-wrap md:justify-between content-start md:min-h-[300px]`}>
                                    <View style={tw`md:flex-row md:flex-wrap md:px-1 w-full max-w-full`}>
                                        <SpecsCard
                                            style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.NAME }
                                            labelImage={{ uri: require('../svg/'+theme+'/name.svg') }}
                                            value={viewingAssetObj.name}
                                        />
                                        <SpecsCard
                                            style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.TYPE }
                                            labelImage={{ uri: require('../svg/'+theme+'/attachment.svg') }}
                                            value={ viewingAssetObj.type_id ? documentTypeOptions.find(obj => {return obj.value == viewingAssetObj.type_id})?.label : '' }
                                        />
                                        <SpecsCard
                                            style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.DATE +" "+ labels.current?.EXPIRES }
                                            labelImage={{ uri: require('../svg/'+theme+'/calendar_blue.svg') }}
                                            value={ viewingAssetObj.expiration_date ? YYYYMMDD_to_MMDDYYYY(viewingAssetObj.expiration_date) : 'N/A' }
                                        />
                                        { '' !== viewingAssetObj.uri && null !== viewingAssetObj.uri &&
                                            <SpecsCard
                                                style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                                label={ labels.current?.VIEW +' '+ labels.current?.DOCUMENT_FILE }
                                                labelImage={{ uri: require('../svg/'+theme+'/url.svg') }}
                                                onPress={() => {  window.open(viewingAssetObj.uri, '_blank'); }}
                                            />
                                        }
                                    </View>

                                    <View style={tw`w-full p-1 max-w-full`}>
                                        <Heading hlevel={2} styleHlevel={tw.prefixMatch('md') ? 3 : 5} style={tw`mt-5`}>{ labels.current?.DETAILS }</Heading>
                                        <Span>{ viewingAssetObj.details }</Span>
                                    </View>
                                </View>

                            </ScrollView>
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.TYPE }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={documentTypeSelectVisible}
                setActive={setDocumentTypeSelectVisible}
                selectedValue={documentType}
                options={documentTypeOptions}
                onValueChange={(value) => {setDocumentType(value)}}
                scrollable={documentTypeOptions.length > 8}
                searchable={documentTypeOptions.length > 8}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.VIEW }
                optionSet="radio"
                buttonTitle={ labels.current?.SORT }
                active={filterVisible}
                setActive={setFilterVisible}
                selectedValue={getFilter}
                options={documentTypeFilterOptions}
                onValueChange={(value) => {setFilter(value)}}
                scrollable={documentTypeFilterOptions.length > 8}
                searchable={documentTypeFilterOptions.length > 8}
            />

            <Modal
                active={modalActive}
                setActive={setModalActive}
            >
                <Heading hlevel={2} styleHlevel={3}>{ labels.current?.DELETE_CONFIRM_TITLE }</Heading>
                <Span>{ labels.current?.DELETE_CONFIRM_BODY }</Span>
                <Span style={tw`text-red mt-2`}>{ labels.current?.PERMANENT_ACTION }</Span>
                <View style={tw`flex-row mt-5`}>
                    <Button
                        title={ labels.current?.CANCEL }
                        style={tw`w-full shrink py-2 bg-white border border-[${themes[theme].brand_primary}]`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-[${themes[theme].brand_primary}]`}
                        styleHoverText={tw`text-[${themes[theme].brand_primary}]`}
                        onPress={()=>{setModalActive(false)}}
                    />
                    <Button
                        title={ labels.current?.DELETE }
                        image={{ uri: require('../svg/delete_white.svg') }}
                        style={tw`ml-2 w-full shrink py-2 bg-red border border-red`}
                        styleHover={tw`bg-red`}
                        styleText={tw`text-white`}
                        styleHoverText={tw`text-white`}
                        onPress={()=>{
                            setPrimaryMsg('');
                            let payload = {
                                object_type: photoObjectType.current,
                                object_id: yachtID,
                                vessel_id: yachtID,
                                id: modalAffectID,
                            };

                            fetch(apiUrl, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    endpoint: 'documents/delete', v: apiVersion,
                                    client_key: localStorage.getItem('clientKey'),
                                    device_id: localStorage.getItem('deviceID'),
                                    payload: payload
                                }),
                            })
                            .then((response) => {
                                return response.text().then(function(text) {
                                    const result = JSON.parse(text);
                                    if ( result.error )
                                    {
                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                        toast.show(result.error, {type: 'danger', duration:10000});
                                    }
                                    else
                                    {
                                        setModalAffectID('');
                                        setModalActive(false);
                                        toast.show(labels.current?._ASSET, {type: 'success', duration:2000});
                                        documentMimeType.current = '';

                                        setTimeout(()=>{
                                            setLastUpdate(now()); // forces app to refetch all account data
                                        }, 250);
                                    }
                                });
                            })
                            .catch(error => {
                                console.warn(error);
                                if ( ! window.navigator.onLine ) { toast.show('Internet connection failed. Please try again.', {type: 'danger', duration:10000}); }
                                else { toast.show('An error ocurred. See console log.', {type: 'danger', duration:10000}); }
                            });
                        }}
                    />
                </View>
            </Modal>
        </View>
    )
}

export default YachtDocumentsView;
